.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 8rem;
}
.topContainer {
  display: flex;
  justify-content: space-between;
}
.topSubContainer {
  width: 60%;
}
.topSubContainer2 {
  width: 40%;
}
.logout {
  position: absolute;
  color: white;
  display: flex;
  align-items: center;
  right: 10rem;
  top: 5rem;
  font-size: 1.8rem;
  gap: 0.5rem;
  cursor: pointer;
}
.logouticon {
  width: 3.5rem;
  height: 3.5rem;
  fill: #ffffff;
  cursor: pointer;
}
.heading {
  font-family: 'Sarina';
  font-style: normal;
  font-weight: 400;
  font-size: 4rem;
  line-height: 6rem;
  color: #ffffff;
}
.subheading {
  margin-top: 1.5rem;
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.2rem;
  color: #ffffff;
}
.paragraph {
  margin-top: 1.5rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 1.8;
  width: 78.8rem;
  color: #ffffff;
}
.inputContainer {
  display: flex;
  gap: 2rem;
  margin-top: 1.5rem;
}
.label {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #ffffff;
  margin-left: 0.4rem;
  text-transform: capitalize;
}
.input {
  margin-top: 0.5rem;
  display: flex;
  width: 26rem;
  height: 4.2rem;
  background: #2c355c82;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1rem);
  border-radius: 1rem;
  padding: 0.5rem 1.5rem;
  align-items: center;
}

.input2 {
  margin-top: 0.5rem;
  display: flex;
  width: 28rem;
  height: 12rem;
  background: rgba(49, 62, 118, 0.51);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1rem);
  border-radius: 1rem;
  padding: 0.5rem 1.5rem;
  align-items: center;
}
.address {
  align-items: flex-start;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--light-brown);
}

.icon2 {
  width: 7rem;
  height: 7rem;
  fill: var(--light-brown);
}
.inputText {
  appearance: none;
  border: none;
  resize: none;
  outline: none;
  margin-left: 1rem;
  background-color: transparent;
  color: #ffffff;
}
.textarea {
  padding-top: 0.5rem;
  width: 100%;
  height: 100%;
}
.upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.uploadText {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;

  color: #aa9999;
}
.inputText::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

.buttonContainer {
  margin-top: 2rem;
  display: flex;
  gap: 4.5rem;
}
.button {
  width: 11rem;
  height: 4rem;
  background: rgba(255, 77, 0, 0.47);
  border-radius: 0.5rem;
  appearance: none;
  outline: none;
  border: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
}
.line {
  width: 3rem;
  height: 0.1rem;
  background: #ffffff;
}
.bottomContainer {
  margin-top: 4rem;
}
.img {
  width: 40%;
  height: 90%;
}
.error {
  font-size: 1.2rem;
  color: var(--red);
  /* text-align: center; */
}

.confirmCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirmCard {
  width: 34rem;
  background: #303c6d;
  border-radius: 15px;
  padding: 1rem 2rem;
}
.confirmCardHeader {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 3rem;
  color: #ffffff;
  text-align: center;
  margin-top: 1rem;
}
.confirmCardcontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 1.5rem;
  margin-top: 1rem;
}
.confirmCardTag1 {
  margin-top: 1rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.confirmCardText {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.6rem;
  color: #ffffff;
}
.confirmCardTextBold {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 4.2rem;
  color: #ffffff;
}
.confirmCardDesc {
  margin-top: 1.5rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #ffffff;
}
.confirmCardButton {
  margin-top: 2.5rem;
  width: 26.1rem;
  height: 3.8rem;
  background: #e56e00;
  border-radius: 1rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
}
.confirmCardButtonClose {
  margin-top: 2.5rem;
  width: 100%;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e56e00;
  margin-left: -1.5rem;
  margin-bottom: 1rem;
}
.close {
  cursor: pointer;
}
.modalContainer {
  display: none;
  pointer-events: auto;
  background: rgba(49, 62, 118, 0.51) !important;
  /* background: rgba(48, 60, 109, 0.78); */
  -moz-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  backdrop-filter: blur(2px);
}

.modal {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 1.1s ease-out;
  opacity: 1;
  visibility: visible;
  width: 40vw;
  min-height: 25vh;
  color: var(--white2);
  /* padding: 1rem 2rem; */
}

.circle {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 1.2rem;
  background: #e56e00;
}
.tiles {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}
.modalText {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #ffffff;
}
.modalEmail {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #ffffff;
}
.modalIcon {
  width: 2rem;
  height: 1.6rem;
  fill: #aa9999;
}

@media (max-width: 575.98px) {
  .container {
    background-position: center;
    background-repeat: no-repeat;
    padding: 1rem 2rem;
    overflow: auto;
    padding-bottom: 4rem;
  }
  .heading {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  .topSubContainer {
    width: 100%;
  }
  .logout {
    position: absolute;
    color: white;
    display: flex;
    align-items: center;
    right: 2rem;
    top: 2rem;
    font-size: 1.6rem;
    gap: 0.5rem;
    cursor: pointer;
  }
  .logouticon {
    width: 2.6rem;
    height: 2.6rem;
    fill: #ffffff;
    cursor: pointer;
  }
  .subheading {
    margin-top: 1.2rem;

    font-size: 1.6rem;
    line-height: 2rem;
  }
  .paragraph {
    margin-top: 1.5rem;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    width: 100%;
    color: #ffffff;
  }
  .inputContainer {
    display: flex;
    gap: 2rem;
    margin-top: 1.5rem;
    flex-direction: column;
  }
}

@media (min-width: 575px) and (max-width: 767.98px) {
  .container {
    background-position: center;
    background-repeat: no-repeat;
    padding: 1rem 2rem;
    overflow: auto;
    padding-bottom: 4rem;
  }
  .heading {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  .topSubContainer {
    width: 100%;
  }
  .logout {
    position: absolute;
    color: white;
    display: flex;
    align-items: center;
    right: 2rem;
    top: 2rem;
    font-size: 1.6rem;
    gap: 0.5rem;
    cursor: pointer;
  }
  .logouticon {
    width: 2.6rem;
    height: 2.6rem;
    fill: #ffffff;
    cursor: pointer;
  }
  .subheading {
    margin-top: 1.2rem;

    font-size: 1.6rem;
    line-height: 2rem;
  }
  .paragraph {
    margin-top: 1.5rem;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    width: 100%;
    color: #ffffff;
  }
  .inputContainer {
    display: flex;
    gap: 2rem;
    margin-top: 1.5rem;
    flex-direction: column;
  }
}

@media (min-width: 767px) and (max-width: 991.98px) {
  .container {
    background-position: center;
    background-repeat: no-repeat;
    padding: 2rem 4rem;
    overflow-y: auto;
    padding-bottom: 4rem;
  }
  .heading {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  .label {
    font-size: 1.7rem;
    line-height: 1.9rem;
  }

  .topSubContainer {
    width: 100%;
  }
  .logout {
    position: absolute;
    color: white;
    display: flex;
    align-items: center;
    right: 2rem;
    top: 2rem;
    font-size: 1.6rem;
    gap: 0.5rem;
    cursor: pointer;
  }
  .logouticon {
    width: 3.6rem;
    height: 3.6rem;
    fill: #ffffff;
    cursor: pointer;
  }
  .subheading {
    margin-top: 2.2rem;
    font-size: 2rem;
    line-height: 2rem;
  }
  .paragraph {
    margin-top: 2.5rem;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.8rem;
    width: 100%;
    color: #ffffff;
  }
  .inputContainer {
    display: flex;
    gap: 2rem;
    margin-top: 1.5rem;
    flex-direction: column;
  }
  .inputText {
    margin-left: 1rem;
    font-size: 1.6rem;
  }
  .icon {
    width: 2rem;
    height: 2rem;
    fill: var(--light-brown);
  }
  .input2 {
    width: 32rem;
    height: 14rem;
  }
  .button {
    width: 13rem;
    height: 4.8rem;
    background: rgba(255, 77, 0, 0.47);
    border-radius: 0.5rem;
    appearance: none;
    outline: none;
    border: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    font-size: 1.8rem;
  }

  .input {
    margin-top: 0.5rem;
    display: flex;
    width: 30rem;
    height: 4.8rem;
    background: #2c355c82;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1rem);
    border-radius: 1rem;
    padding: 0.5rem 1.5rem;
    align-items: center;
  }
}
