.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.label {
  margin-top: 1rem;
  color: var(--primary);
  font-size: 1.6rem;
}
.promoContainer {
  margin-top: 1.5rem;
}
.chooseContainer {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
}
.radioContainer {
  display: block;
  position: relative;
  padding-left: 3.5rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  font-size: 1.4rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--primary);
}

.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.2rem;
  width: 2.2rem;
  background-color: #b6d7ff;
  border-radius: 50%;
}

.radioContainer:hover input ~ .checkmark {
  background-color: #313e76;
}

.radioContainer input:checked ~ .checkmark {
  background-color: #b6d7ff;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radioContainer input:checked ~ .checkmark:after {
  display: block;
}

.radioContainer .checkmark:after {
  top: 0.5rem;
  left: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: #313e76;
}
.paginationContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
}

.paginationBtn {
  font-size: 1.8rem;
  padding: 1rem 2rem;
  background-color: var(--primary-light);
  color: #ffffff;
  border-radius: 0.5rem;
}

.paginationBtn:hover {
  background-color: var(--primary);
  cursor: pointer;
}
.true {
  pointer-events: none;
  background-color: #9e9e9e;
}
.false {
  pointer-events: all;
  background-color: #404f90;
}
