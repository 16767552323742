.container {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 8rem;
  overflow-y: auto;
}
.topContainer {
  height: 95vh;
  /* overflow-y: auto;
  overflow-x: hidden; */
}
.topButtonContainer {
  display: flex;
  justify-content: space-between;
}
.backButton {
  margin-top: 1rem;
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;
}
.heading {
  margin-top: 1rem;
  font-family: 'Sarina';
  font-style: normal;
  font-weight: 400;
  font-size: 4rem;
  line-height: 6rem;
  color: #ffffff;
}
.logout {
  margin-top: 1.5rem;
}
.logouticon {
  width: 3.5rem;
  height: 3.5rem;
  fill: #ffffff;
  cursor: pointer;
}
.subheading {
  margin-top: 1.5rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 2.2rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
}
.title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.9rem;
  color: #ffffff;
  text-transform: uppercase;
}
.paymentContainer {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}
.paymentSubContainer1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 15rem;
}
.paymentSubContainer2 {
  width: 50%;
}
.subscription {
  margin-top: 2rem;
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}
.planSubContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.nextIcon {
  width: 2.5rem;
  height: 8.5rem;
  margin-left: 2rem;
}
.prevIcon {
  width: 2.5rem;
  height: 8.5rem;
  transform: rotate(180deg);
  margin-right: 2rem;
}

.nextIcon:hover,
.prevIcon:hover {
  cursor: pointer;
}
.paginationContainer {
  display: flex;
  flex-direction: column;
}
.planCircle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: '100%';
  margin-top: 1rem;
}

.planCircle div {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 2.2rem;
  background-color: #e56e00;
  margin: 0 1rem;
}
.planCard {
  width: 40rem;
  background: rgba(49, 62, 118, 0.51);
  border-radius: 10px;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* .planCard:focus {
  border: 2px solid white;
} */

.planCardTitle {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 2.9rem;
  line-height: 2.3rem;
  text-align: center;
  color: #ffffff;
}
.priceContainer {
  display: flex;
  margin-top: 1.4rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.price {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 3.4rem;
  line-height: 4.5rem;
  color: #e56e00;
}
.italicLine {
  width: 2.8rem;
  height: 0px;
  margin-left: 0.5rem;
  border: 1px solid #ffffff;
  transform: matrix(-0.24, 0.97, -0.96, -0.25, 0, 0);
}
.perMonth {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  color: #ffffff;
  text-transform: capitalize;
  padding-top: 0.5rem;
}
.planCardDesc {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.5rem;
  color: #f3f2f2;
  text-align: center;
  margin-top: 2rem;
}
.color {
  color: #ffffff;
  /* font-size: 1.1rem; */
}
.tileContainer {
  margin-top: 2.6rem;
  display: flex;
  flex-direction: column;
  gap: 2.3rem;
  margin-left: 1rem;
}
.tiles {
  display: flex;
  gap: 1rem;
}
.cardIconWrapper {
  width: 2.2rem;
  height: 2.2rem;
}
.icon2 {
  width: 2.5rem;
  height: 2.5rem;
}
.tileText {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #ffffff;
  margin-left: 1rem;
}

.planCardButton {
  margin-top: 2rem;
  width: 80%;
  height: 4.6rem;
  background: #e56e00;
  border-radius: 10px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
  align-self: center;
}
.formContainer {
  width: 50%;
  height: 100%;
  margin-top: 1rem;
}
.form {
  width: 36rem;
}

.inputText {
  width: 100%;
  height: 100%;
  appearance: none;
  outline: none;
  border: none;
  resize: none;
  background-color: transparent;
  color: #ffffff;
}
.inputText::placeholder {
  color: #aa9999;
}
.dateContainer {
  display: flex;
  gap: 2rem;
}
.date {
  width: 10rem;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
}
.button {
  width: 12rem;
  height: 4.6rem;
  background: rgba(255, 77, 0, 0.47);
  border-radius: 0.5rem;
  appearance: none;
  outline: none;
  border: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
}
.skip {
  width: 11rem;
  height: 4rem;
  border-radius: 0.5rem;
  appearance: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  background-color: transparent;
  color: #ffffff;
  /* color: var(--secondary); */
}
.line {
  width: 3rem;
  height: 0.1rem;
  background: #ffffff;
}
.login_container_loginBox_inputBoxError {
  text-align: center;
  margin-top: 0.5rem;
  color: var(--red2);
  font-size: 1.2rem;
  font-weight: 500;
  width: 28rem;
}
.icon {
  width: 5rem;
  height: 5rem;
}
.confirmCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirmCard {
  width: 34rem;
  background: #303c6d;
  border-radius: 15px;
  padding: 1rem 2rem;
}
.confirmCardHeader {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 3rem;
  color: #ffffff;
  text-align: start;
  margin-top: 1rem;
}
.confirmCardcontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 1rem;
}
.confirmCardTopCantainer {
  margin: 0 auto;
}
.confirmCardTag1 {
  margin-top: 1rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.confirmCardText {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 1.9rem;
  line-height: 2.1rem;
  color: #ffffff;
}
.confirmCardTextBold {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #ffffff;
}
.confirmCardDesc {
  margin-top: 1.5rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #ffffff;
}
.confirmCardButton {
  margin-top: 2.5rem;
  width: 26.1rem;
  height: 3.8rem;
  background: #e56e00;
  border-radius: 1rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
  margin: 2rem auto 0 auto;
}
.confirmCardButtonClose {
  margin-top: 2.5rem;
  width: 100%;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e56e00;

  margin-bottom: 1rem;
  margin: 2.5rem auto 1rem auto;
}
.close {
  cursor: pointer;
}
.modalContainer {
  display: none;
  pointer-events: auto;
  background: rgba(49, 62, 118, 0.51) !important;
  /* background: rgba(48, 60, 109, 0.78); */
  -moz-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  backdrop-filter: blur(2px);
}

.modal {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 1.1s ease-out;
  opacity: 1;
  visibility: visible;
  width: 40vw;
  min-height: 25vh;
  color: var(--white2);
  /* padding: 1rem 2rem; */
}
.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}
.loaderText1 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;

  color: #ffffff;
}
.inputContainer {
  width: 24rem;
  background-color: #313e7682;
  border-radius: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
  margin-top: 2rem;
}
.input {
  border: none;
  appearance: none;
  outline: none;
  width: 100%;
  height: 4.6rem;
  background-color: #313e7682;
  padding-left: 1.5rem;
  color: white;
}
.input::placeholder {
  color: white;
  font-weight: 500;
  font-size: 1.4rem;
  font-family: 'Raleway';
  font-style: normal;
  line-height: 1.6rem;
}
.iconclear {
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  right: 1rem;
  top: 25%;
  cursor: pointer;
}
.couponContainer {
  position: relative;
  margin-top: 1rem;
}
.inputSuperContainer {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
}
.couponLoader {
  position: absolute;
  top: 1rem;
  right: -8rem;
  z-index: 1;
}
.calculationsContainer {
  width: 42rem;
  background-color: #313e7682;
  border-radius: 1rem;
  display: flex;
  padding: 1.5rem;
  margin-top: 2rem;
}
.calculationsSection {
  width: 50%;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.calculationsSection2 {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-left: 1px solid #aa9999;
}
.originalText {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.9rem;

  color: white;
}
.discountText {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.9rem;
  color: #04da00;
}
.payableText {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: white;
  border-top: 1px solid #aa9999;
  padding-top: 1rem;
}
@media (max-width: 575.98px) {
  .container {
    background-position: center;
    background-repeat: no-repeat;
    padding: 1rem 2rem;
    padding-bottom: 4rem;
  }
  .prevIcon, .nextIcon{
    width: 1.5rem;
    height: 3.5rem;
  }
  .planCircle {
    margin-top: inherit;
    margin: 2rem 0;
  }
  .subscription {
    overflow-x: auto;
  }
  .icon {
    width: 3rem;
    height: 3rem;
  }
  .logouticon {
    width: 2.6rem;
    height: 2.6rem;
  }
  .heading {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  .title {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
  .paymentSubContainer1 {
    margin-top: 1rem;
    align-items: center;
    width: 100%;
    padding: 0rem
  }
  .paymentSubContainer1 .title {
    text-align: center;
  }
  .paymentSubContainer1 .buttonContainer {
    justify-content: center;
  }
  .paymentSubContainer2 {
    width: 100%;
  }
  .planCard {
    width: 25rem;
    border-radius: 1rem;
    padding: 1em;
  }
  .paymentContainer {
    flex-direction: column;
  }
  .planCardTitle {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .price {
    font-size: 2rem;
    line-height: 3rem;
  }
  .italicLine {
    width: 1.2rem;
  }
  .perMonth {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 0.4rem;
  }
  .planCardDesc {
    margin-top: 1rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
  }
  .cardIconWrapper {
    width: 0.8rem;
    height: 0.8rem;
  }
  .icon2 {
    width: 0.8rem;
    height: 0.8rem;
  }
  .tileText {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  .planCardButton {
    margin-top: 2rem;
    height: 2.6rem;
    border-radius: 1rem;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.4rem;
  }

  .inputContainer {
    width: 18rem;
    border-radius: 1rem;
    margin-top: 1rem;
  }
  .input {
    height: 3.4rem;
    padding-left: 1.5rem;
    color: white;
  }
  .input::placeholder {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .button {
    width: 10rem;
    height: 3.4rem;
    background: rgba(255, 77, 0, 0.47);
    font-size: 1.2rem;
  }
  .line {
    width: 2.4rem;
  }
  .couponLoader {
    position: absolute;
    top: -1rem;
    right: -2rem;
    z-index: 1;
  }

  .originalText {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  .discountText {
    font-size: 1.2rem;
    line-height: 1rem;
  }
  .payableText {
    font-size: 1.2rem;
    line-height: 2.1rem;
  }
  .calculationsContainer {
    width: 28rem;
    padding: 1rem;
    margin-top: 1rem;
  }
  .loaderText1 {
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: center;
  }
  .confirmCardHeader {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-top: 1rem;
  }
  .confirmCardText {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .confirmCardTextBold {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .confirmCardDesc {
    margin-top: 1.2rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  .confirmCardButton {
    margin-top: 2rem;
    width: 26rem;
    height: 3.4rem;
    border-radius: 1rem;
    font-size: 1.6rem;
    line-height: 1.6rem;

    margin: 2rem auto 0 auto;
  }
  .confirmCardButtonClose {
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    margin: 2.5rem auto 1rem auto;
  }
  .formContainer {
    margin-bottom: 2rem;
  }
}

@media (min-width: 575px) and (max-width: 767.98px) {
  .container {
    background-position: center;
    background-repeat: no-repeat;
    padding: 1rem 2rem;
    padding-bottom: 4rem;
  }
  .subscription {
    overflow-x: auto;
  }
  .icon {
    width: 3rem;
    height: 3rem;
  }
  .logouticon {
    width: 2.6rem;
    height: 2.6rem;
  }
  .heading {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  .title {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
  .paymentSubContainer1 {
    margin-top: 1rem;
    width: 100%;
  }
  .paymentSubContainer2 {
    width: 100%;
  }
  .planCard {
    width: 25rem;
    border-radius: 1rem;
    padding: 1em;
  }
  .paymentContainer {
    flex-direction: column;
  }
  .planCardTitle {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .price {
    font-size: 2rem;
    line-height: 3rem;
  }
  .italicLine {
    width: 1.2rem;
  }
  .perMonth {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 0.4rem;
  }
  .planCardDesc {
    margin-top: 1rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
  }
  .cardIconWrapper {
    width: 0.8rem;
    height: 0.8rem;
  }
  .icon2 {
    width: 0.8rem;
    height: 0.8rem;
  }
  .tileText {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  .planCardButton {
    margin-top: 2rem;
    height: 2.6rem;
    border-radius: 1rem;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.4rem;
  }

  .inputContainer {
    width: 18rem;
    border-radius: 1rem;
    margin-top: 1rem;
  }
  .input {
    height: 3.4rem;
    padding-left: 1.5rem;
    color: white;
  }
  .input::placeholder {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .button {
    width: 10rem;
    height: 3.4rem;
    background: rgba(255, 77, 0, 0.47);
    font-size: 1.2rem;
  }
  .line {
    width: 2.4rem;
  }
  .couponLoader {
    position: absolute;
    top: -1rem;
    right: -2rem;
    z-index: 1;
  }

  .originalText {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  .discountText {
    font-size: 1.2rem;
    line-height: 1rem;
  }
  .payableText {
    font-size: 1.2rem;
    line-height: 2.1rem;
  }
  .calculationsContainer {
    width: 28rem;
    padding: 1rem;
    margin-top: 1rem;
  }
  .loaderText1 {
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: center;
  }
  .confirmCardHeader {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-top: 1rem;
  }
  .confirmCardText {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .confirmCardTextBold {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .confirmCardDesc {
    margin-top: 1.2rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  .confirmCardButton {
    margin-top: 2rem;
    width: 26rem;
    height: 3.4rem;
    border-radius: 1rem;
    font-size: 1.6rem;
    line-height: 1.6rem;

    margin: 2rem auto 0 auto;
  }
  .confirmCardButtonClose {
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    margin: 2.5rem auto 1rem auto;
  }
}

@media (min-width: 767px) and (max-width: 991.98px) {
  .container {
    background-position: center;
    background-repeat: no-repeat;
    padding: 2rem 4rem;
    padding-bottom: 4rem;
  }
  .subscription {
    overflow-x: auto;
  }
  .icon {
    width: 4rem;
    height: 4rem;
  }
  .logouticon {
    width: 3.6rem;
    height: 3.6rem;
  }
  .heading {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 2.8rem;
    line-height: 2.8rem;
  }

  .title {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.4rem;
  }
  .paymentSubContainer1 {
    margin-top: 2rem;
    width: 100%;
  }
  .paymentSubContainer2 {
    width: 100%;
  }
  .planCard {
    width: 28rem;
    border-radius: 1rem;
    padding: 1em;
  }
  .paymentContainer {
    flex-direction: column;
  }
  .priceContainer {
    margin-top: 1rem;
  }
  .planCardTitle {
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2.2rem;
  }
  .price {
    font-size: 2.8rem;
    line-height: 3rem;
  }
  .italicLine {
    width: 1.2rem;
  }
  .perMonth {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.6rem;
    margin-bottom: 0.4rem;
  }
  .planCardDesc {
    margin-top: 1.5rem;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.2rem;
  }
  .cardIconWrapper {
    width: 1.4rem;
    height: 1.4rem;
  }
  .icon2 {
    width: 1.4rem;
    height: 1.4rem;
  }
  .tileText {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  .planCardButton {
    margin-top: 2rem;
    height: 3.6rem;
    border-radius: 1rem;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

  .inputContainer {
    width: 24rem;
    border-radius: 1rem;
    margin-top: 1rem;
  }
  .input {
    height: 4.2rem;
    padding-left: 1.5rem;
    color: white;
  }
  .input::placeholder {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  .button {
    width: 14rem;
    height: 4.2rem;
    background: rgba(255, 77, 0, 0.47);
    font-size: 1.7rem;
  }
  .line {
    width: 2.8rem;
  }
  .couponLoader {
    position: absolute;
    top: -1rem;
    right: 20rem;
    z-index: 1;
  }

  .originalText {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
  .discountText {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
  .payableText {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }
  .calculationsContainer {
    width: 36rem;
    padding: 1rem;
    margin-top: 1rem;
  }
  .loaderText1 {
    font-size: 1.8rem;
    line-height: 1.8rem;
    text-align: center;
  }
  .confirmCardHeader {
    font-size: 2.8rem;
    line-height: 2.8rem;
    margin-top: 1rem;
  }
  .confirmCardText {
    font-size: 2rem;
    line-height: 2rem;
  }
  .confirmCardTextBold {
    font-size: 2rem;
    line-height: 2rem;
  }
  .confirmCardDesc {
    margin-top: 1.6rem;
    font-size: 1.8rem;
    line-height: 1.6rem;
  }
  .confirmCardButton {
    margin-top: 2rem;
    width: 26rem;
    height: 4.2rem;
    border-radius: 1rem;
    font-size: 1.8rem;
    line-height: 1.6rem;

    margin: 2rem auto 0 auto;
  }
  .confirmCardButtonClose {
    margin-top: 2rem;
    font-size: 1.8rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    margin: 2.5rem auto 1rem auto;
  }
  .modal {
    width: 50vw;
    min-height: 25vh;
  }
}
