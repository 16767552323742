.client_card_header {
	min-width: 10vw;
	display: flex;
	justify-content: space-between;
	padding-bottom: 1.5rem;
	margin-bottom: 2rem;
	border-bottom: 1px solid var(--light-grey);
}
.user_card_name {
	font-size: 1.4rem;
	font-weight: 600;
}
.user_card_mobile_email {
	display: flex;
	gap: .5rem;
	align-items: center;
}
.user_card_mobile_icon,
.user_card_email_icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--secondary);
}
.user_card_mobile_number,
.user_card_email_number {
	font-size: 1.3rem;
	font-weight: 500;
}
