.container {
  margin: 1rem;
}
.label {
  font-size: 2rem;
  color: var(--primary);
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 1rem;
}
.section {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 4rem;
  column-gap: 10rem;
  padding: 1rem;
  border-bottom: 1px solid var(--primary);
  padding-bottom: 3rem;
}
.tilesContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.title {
  font-size: 1.8em;
  color: var(--primary);
}
.icon {
  width: 2rem;
  height: 2rem;
}
.buttonContainer {
  margin-top: 4rem;
}
