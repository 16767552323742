.addResponseVideo {
  width: 100%;
}
.title {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  color: var(--primary);
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.text1 {
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--black);
}
.text2 {
  font-weight: 500;
  font-size: 1rem;
  color: var(--secondary);
}
.container_icon_arrow {
  width: 1.2rem;
  height: 1.2rem;
  fill: var(--secondary);
}
.filter {
  width: 50px;
  display: flex;
  justify-content: space-between;
}

.container_left {
  width: 50%;
  padding-right: 5px;
}
.container_left_header,
.container_right_header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5px;
}

.container_right {
  width: 50%;
  padding-left: 5px;
}

.container_left_content,
.container_right_content {
  overflow-y: auto;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
}

.boxContainer {
  width: 45%;
  margin: 10px;
}
.box {
  padding: 12px;
  width: 100%;
  height: 159px;
  background-color: var(--dark-white);
  border-radius: 10px;
}
.box_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box_title {
  margin: 5px;
  font-weight: 500;
  font-size: 1.2rem;
}
.selected {
  width: 100%;
  height: 45px;

  padding: 5px;
  display: flex;
  justify-content: center;
}
.customButton {
  width: 60%;
  height: 100%;
  color: var(--primary);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
  border: 1px solid var(--primary);
}
.activeCustomButton {
  width: 60%;
  height: 100%;
  color: white;
  background-color: var(--primary);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
}

.customButton:active,
.customButton:hover {
  background-color: var(--primary-light);
  color: white;
}
.pdf {
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c4c4c4;
  border-radius: 10px;
}
.img {
  width: 100%;
  height: 110px;
}
.checkmark {
  color: var(--primary);
}
.danger {
  color: var(--red);
  font-size: 1.1rem;
  margin-top: 3px;
  margin-left: 5px;
}

@media (min-width: 1800px) {
  .img {
    width: 70%;
    height: 110px;
  }
}
