.reports_graph_toggle_container{
    justify-content: flex-end;
    display: flex;
    align-items: center;
    justify-self: flex-end;
    margin-top: 2rem;
}

.reports_graph_ontainer{
    background-color: #E5E5E5;
    display: flex;
    gap: .5rem;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
}

.reports_graph_toggle{
    background-color: var(--grey);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
    padding: 1rem 2rem;
    border-radius: .8rem;
}

.active_report_chart{
    background: #FFFFFF;
    box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.15);
}

.reports_graph_toggle_image{
    width: 2rem;
}

.reports_graph_toggle_label{
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: capitalize;
}

.report_chart_container{
    max-height: 62rem;
}