:root {
  --bg-color: #fdfdfd;
  --primary: #303c6d;
  --primary-light: #404f90;
  --secondary: #e56e00;
  --secondary-light: #f4c599;
  --white: #ffffff;
  --transparent: transparent;
  --light-white: #f5f5f5;
  --dark-white: #e5e5e5;
  --gray: #f5f4f4;

  --bg-light: #f0f0f1;
  --lightest-grey: #d6d6d6;
  --dark-grey: #64666b;
  --light-grey: #9e9e9e;
  --black: rgb(27, 27, 27);
  --light-black: rgba(24, 24, 24, 0.226);
  --red: #ff0000;
  --light-red: #ff336638;
  --light-red: #ff33662f;
  --yellow: #e1a82d;
  --light-yellow: #ffb20b;
  --blue: #015fd1;
  --green: #04da00;
  --light-blue: #c9ddf7;
  --lightest-blue: #b6d7ff;
  --switch-background: #b6d7ff;
  --skeleton: #0000001c;
  --yellow-skeleton: #e1a82d3d;
  --red-skeleton: #ff000038;
  --placeholder: #aa9999;

  --bg-color2: #f3f2f2;
  --primary-color2: #303c6d;
  --secondary-color2: #e56e00;
  --grey2: #5e5e5e;
  --dark-grey2: #292929;
  --light-grey2: #dddddd;
  --white2: #ffffff;
  --black2: #000000;
  --blue2: #495eb4;
  --dark-blue3: #1e7098;
  --dark-blue2: #1e7098;
  --light-blue2: #b6d7ff;
  --lightest-blue2: rgba(182, 215, 255, 0.35);
  --green2: #04da00;
  --button-green2: #779559;
  --light-green2: #a9f9a8;
  --red2: #ff4c00;
  --error2: #a94136;
  --light-red2: #ffccb6;
  --orange2: #ff4d00;
  --light-brown: #8e8e8e;
}

*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  font-family: 'Poppins', sans-serif;
}

input,
textarea {
  font-family: 'Poppins', sans-serif;
}
.flex {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.btn {
  cursor: pointer;
}
.green-bg {
  background-color: var(--green) !important;
  color: var(--white);
}
.red-bg {
  background-color: var(--red) !important;
  color: var(--white);
}
@font-face {
  font-family: 'Segoe';
  src: local('Segoe'), url('./Assets/Fonts/segoe.woff2') format('woff2');
}

@font-face {
  font-family: 'Segoe2';
  src: local('Segoe2'), url('./Assets/Fonts/segoe2.woff') format('woff');
}
