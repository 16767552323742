.privacy_main_content{
    display: grid;
	grid-template-columns: repeat(1, minmax(min-content, 1fr));
    margin-top: 5rem;
    max-height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.privacy_default_content{
    overflow: auto;
    height: 50vh;
    width: 100%;
}

.policy_container{
    background-color:  var(--gray);
    border-radius: 1.5rem;
    padding: 4rem;
}

@media (max-width: 500px) {
    .policy_container{
        padding: 2rem;
    }    
}