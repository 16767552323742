.container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  position: relative;
}
.subContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 7rem;
  align-self: start;

  margin-top: 5rem;
}
.modalContainer {
  width: 30rem;
  background-color: white;
  display: flex;
  padding: 1rem;
  border-radius: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  color: var(--primary);
}
.img2 {
  /* position: absolute;
  top: 2rem; */
  width: 14rem;
  height: 17rem;
  /* margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center; */
}
.list {
  list-style-type: none;
  size: 1rem;
}
.message {
  margin-top: 1rem;
  text-align: center;
}

.btn {
  font-family: inherit;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--white);
  font-size: 1.5rem;
  margin-top: 2rem;
  padding: 1rem 2rem;
  cursor: pointer;

  display: flex;
  gap: 0.5rem;
  align-items: center;
  transition: all 0.4s;
}
.btn:hover {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
}
@media (max-width: 575.98px) {
}

@media (min-width: 575px) and (max-width: 767.98px) {
}

@media (min-width: 767px) and (max-width: 991.98px) {
  .subContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 7rem;
    align-self: start;

    margin-top: 5rem;
  }
  .img2 {
    width: 24rem;
    height: 27rem;
  }
  .modalContainer {
    width: 65rem;
    padding: 4rem;
    border-radius: 2rem;
    font-size: 3rem;
  }
  .btn {
    font-size: 3rem;
    padding: 2rem 4rem;
    border-radius: 1rem;
  }
}
