.howto_card_container{
    background-color: var(--gray);
    padding: 2rem 2.5rem;
    border-radius: 2rem;
    margin-bottom: 3rem;
    width: max-content;
    box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
}

.howto_card_title_view{
    display: flex;
    align-items: center;
}

.howto_card_leftview{
    display: flex;
    align-items: center;
}

.howto_biglogo{
    height: auto;
    width: 4rem;
}

.howto_card_rightview{
    margin-left: 6rem;
    justify-content: space-between;
    display: flex;
}

.howto_card_editlogo{
    height: 2.8rem;
    width: 2.8rem;
    cursor: pointer;
}

.color_of_howto{
    font-size: 1.5rem;
    font-weight:600;
}

.color_circle_of_howto{
    height: 2rem;
    width: 2rem;
    border-radius: 4rem;
}