.faq_main_content{
    margin-top: 5rem;
    width: 100%; 
    display: flex;
}

.faq_list{
    max-height: 80vh;
    overflow-y: auto;
}

.add_faq_section{
    background-color: var(--gray);
    border-radius: 2rem;
    padding: 3rem;
    margin-left: 3rem;
    width: 50%;
    height: 100%;
}

@media (max-width: 900px) {
    .add_faq_section{
        padding: 2rem;
        margin-left: 2rem;
    }
}