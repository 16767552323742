/* Modal */

.modalContainer {
  display: block;
  pointer-events: auto;
  background: rgba(0, 3, 13, 0.85);
  backdrop-filter: blur(10px);
  -moz-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  margin: 1.5rem;
  border-radius: 2rem;
}

.modal {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: var(--bg-color2);
	border-radius: 3rem; */
  /* overflow: hidden; */
  transition: 1.1s ease-out;
  opacity: 1;
  visibility: visible;
  min-width: 70vw;
  min-height: 28vh;
  color: var(--white2);
  /* background-color: red; */
  /* padding: 1rem 2rem; */
}

.modalAnswerNext_again_btns_top {
  margin-bottom: 2rem;
  /* position: absolute;
  top: 0;
  left: 0;
  transform: translate(-10rem, -7rem);
  width: 60vw; */

  /* margin-bottom: 3rem; */
  display: flex;
  justify-content: flex-end;
}
.modalQuestion {
  font-size: 3rem;
  font-weight: 600;
}
.modalQuestion_help {
  font-size: 2.2rem;
  font-weight: 500;
}
.modalQuestion span {
  font-weight: 600;
}
.modalAnswers {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
}
.modalAnswers_rightWrong {
  display: flex;
  gap: 2rem;
  align-items: stretch;
}
.modalAnswers_wrong,
.modalAnswers_right {
  min-width: 30rem;
  border-radius: 2rem;
  /* padding: 0.5rem 0; */
  overflow: hidden;
}
.modalAnswers_rightWrong_options {
  font-size: 2.4rem;
  font-weight: 600;
  padding: 1.6rem 2rem;
  /* padding-bottom: 0rem; */
  border-bottom: 1px solid var(--light-grey2);
  animation: progress 0.5s ease-in-out;
  text-align: center;
}
@keyframes progress {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modalAnswers_wrong {
  background-color: rgba(255, 76, 0, 0.3);
}
.modalAnswers_right {
  background-color: rgba(4, 218, 0, 0.3);
}
.modalAnswers_flex {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--light-grey2);
}
.modalAnswers_icon {
  height: 2.4rem;
  transform: scale(1.8);
  width: 4.4rem;
  fill: var(--light-blue2);
}
.emojiTextOption {
  font-size: 3rem;
}
.modalAnswers_icon{
  margin-top: 0.8rem;
}
.modalAnswers_icon_green,
.modalAnswers_icon_red {
  height: 2.5rem;
  width: 2.5rem;
}
.modalAnswers_rightWrong .modalAnswers_flex{
  padding: 2rem 0;
}
.modalAnswers_icon_green {
  fill: var(--green2);
}
.modalAnswers_icon_red {
  fill: var(--red2);
}
.modalAnswers_flex_text {
  font-size: 2.2rem;
  font-weight: 500;
  min-width: 25rem;
  text-align: center;
}
.modalAnswers_btn,
.modalAnswers_btn_active {
  font-family: inherit;
  border: none;
  cursor: pointer;
  color: var(--white2);
  font-size: 2.2rem;
  padding: 1.5rem 2rem;
  text-align: left;
  display: flex;
  gap: 0.8rem;
  align-items: center;
  transition: all 0.4s;
}
.modalAnswers_btn {
  background-color: transparent;
}
.modalAnswers_btn_active {
  background-color: var(--lightest-blue2);
  border-radius: 0.5rem;
}
.modalAnswerError {
  color: var(--red2);
  font-size: 1.8rem;
}
.modalAnswer_pairs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modalAnswer_pair {
  width: 50%;
}
.modalAnswer_pair_heading {
  font-size: 2.6rem;  
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
}
.modalAnswer_pair_option {
  font-size: 2rem;
  padding: 0.8rem 0;
  border-bottom: 1px solid var(--light-grey2);
  text-align: center;
}
.modalAnswer_pair_option_B {
  width: 100%;
  text-align: center;
  font-size: 2rem;
}
.modalAnswerNext {
  /* position: absolute;
  top: 100%;
  right: -30%; */
  margin-top: 2rem;
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  flex-direction: column;
  /* align-items: flex-start; */
}
.modalAnswerNext_next_btn {
  align-self: flex-end;
  font-family: inherit;
  background-color: var(--white2);
  border: 1px solid var(--lightest-blue2);
  border-radius: 1rem;
  padding: 1rem 2rem;
  color: var(--grey2);
  font-weight: 600;
  font-size: 2.4rem;
  opacity: 1;
  display: flex;
  gap: 1.4rem;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s;
}
.modalAnswerNext_next_btn span {
  height: 1px;
  width: 3rem;
  background-color: var(--grey2);
}
.modalAnswerNext_next_btn:hover span {
  background-color: var(--white2);
}
.modalAnswerNext_next_btn:hover {
  background-color: transparent;
  color: var(--white2);
}

.avatarBtn {
  align-self: center;
  margin-top: 4rem;
}
.buttonAvatar {
  position: absolute;
  top: 65%;
}
.modalAnswerNext_again_btns {
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  align-self: center;
  /* margin-top: 2rem;
  margin-right: 10%; */
}
.modalAnswerNext_again_btn {
  font-family: inherit;
  background-color: transparent;
  border: none;
  font-size: 2.6rem;
  font-weight: 800;
  color: var(--white2);
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}
.modalAnswerNext_again_btn span {
  height: 1.5px;
  width: 3rem;
  background-color: var(--white2);
}

.progress_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.progress_container_heading {
  font-size: 5rem;
  font-weight: 500;
}
.progress_container_count {
  font-size: 2.8rem;
  margin-top: 1.8rem;
  font-weight: 500;
}
.progress_container_bar {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  position: relative;
  width: 50rem;
  height: 2rem;
  overflow: hidden;
  background-color: var(--white2);
  border-radius: 1rem;
}
.progress_container_bar_progress {
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 80%;
  height: 2rem;
  background-color: var(--secondary-color2);
}
.progress_cards {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.progress_card {
  background-color: var(--lightest-blue2);
  padding: 1rem 2rem;
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.progress_card_number {
  font-size: 3.8rem;
  font-weight: 200;
  line-height: 1.2;
}

.modal__heading {
  padding: 0.5rem 2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}
.modal__heading--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.modal__heading2 {
  font-weight: 600;
}
.modal__heading--btn {
  font-family: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.4s;
}
.modal__heading--icon {
  height: 2rem;
  width: 2rem;
  fill: #ff3366;
}
.modal__heading--btn:hover {
  transform: scale(1.2);
}
.modal__text {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.modal__text_coin {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}
.modal__text2 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  color: #ff3366;
}
.modal__data {
  padding-bottom: 2rem;
  margin: 1rem 2rem;
  border-bottom: 1px solid var(--table-heading2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.modal__data--completed {
  background-color: #ff9000;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  color: #e7e7ea;
  font-size: 1.2rem;
}
.modal__data--completedIcon {
  height: 2rem;
  width: 2rem;
  fill: #e7e7ea;
}
.modal__data--timedate {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  color: var(--light-dark2);
  font-size: 1.2rem;
}
.modal__data--timedateIcon {
  height: 2rem;
  width: 2rem;
  fill: var(--light-dark2);
}
.modal__data--content {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
  color: #ff9000;
  font-size: 1.1rem;
}
.modal__data--contentIcon {
  height: 2rem;
  width: 2rem;
  fill: #ff9000;
}
.modal__data--user {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 2rem;
  margin-bottom: 3rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--light-dark2);
}

.modalContainer__form {
  margin: 1rem 2rem;
}

.modal__actions {
  padding: 1rem;
  padding-left: 2rem;
  display: flex;
  gap: 0.5rem;
}

.modal__button {
  font-family: inherit;
  border-radius: 1.5rem;
  padding: 1rem 3rem;
  box-shadow: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
  font-size: 1.3rem;
  line-height: 1;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
}

.modal__button--delete {
  border: 1px solid #ff3366;
  background: #ff3366;
  color: #e7e7ea;
}

.modal__button--delete:hover {
  background: transparent;
  border: 1px solid #ff3366;
  color: #ff3366;
}

.modal__button--update {
  border: 1px solid #ff9000;
  background: #ff9000;
  color: #e7e7ea;
}

.modal__button--update:hover {
  background: transparent;
  border: 1px solid #ff9000;
  color: #ff9000;
}

.modal__button--cancel {
  font-family: inherit;
  border-radius: 1.5rem;
  padding: 1rem 3rem;
  box-shadow: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
  border: 1px solid #ff9000;
  background-color: transparent;
  color: #ff9000;
  font-size: 1.3rem;
  line-height: 1;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
}

.modal__button--cancel:hover {
  background: #ff9000;
  border: 1px solid #ff9000;
  color: #e7e7ea;
}
.modal__text {
  color: var(--light-dark2);
  font-size: 2rem;
}
.underlinedAnswer {
   padding: 0px 3.6rem;
   border-bottom: 0.4rem dashed #ffffff;
}
.modal__text span {
  font-weight: 500;
  font-size: 1.3rem;
  color: #ff9000;
}

.modalContainer__form--section {
  display: flex;
  gap: 2rem;
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  padding-bottom: 2.5rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
}
.modalContainer__form--section-column {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 2rem;
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  padding-bottom: 2.5rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
}
.modalContainer__form--sections {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.modal__radioButtons,
.modal__radioButton {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.modal__radioButtons {
  gap: 1rem;
  gap: 4rem;
  display: flex;
  padding-left: 2rem;
}
.modal__radioButton {
  gap: 0.5rem;
  position: relative;
}
.modal__radioButton--label {
  font-size: 1.2rem;
  font-weight: 600;
  /* padding-left: 35px; */
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal__radioButton--input {
  position: absolute;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
}
.modal__radioButton--radio {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: transparent;
  border: 3px solid #12151a;
}
.modal__radioButton--radio:after {
  content: '';
  position: absolute;
  display: none;
}
.modal__radioButton--input:checked ~ .modal__radioButton--radio {
  border: 3px solid #12151a;
}
.modal__radioButton--input:checked ~ .modal__radioButton--radio:after {
  display: block;
}
.modal__radioButton--radio:after {
  top: 0.5rem;
  left: 0.5rem;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #12151a;
}

@media (max-width: 767px) {
  .modalContainer {
    overflow: scroll;
  }

  .modal {
    width: 90%;
    height: 50vh;
    overflow: auto;
  }
  .modalContainer__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .modalContainer__form--section {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 0 1.5rem 0;
    margin-bottom: 1rem;
  }
  .input__uploadContainer {
    display: flex;
    flex-direction: column;
  }
  .modal_input {
    align-items: center;
  }
}
.fileUpload {
  margin-top: 1rem;
  background-color: #ffffff;
  padding: 2rem 3rem;
  border-radius: 2rem;
  width: 23rem;
  height: 16rem;

  display: flex;
  align-items: center;
  justify-content: center;
}
.fileUpload__label {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  cursor: pointer;
}
.fileUpload__label--icon {
  height: 5rem;
  width: 5rem;
  fill: #12151a;
}
.fileUpload__label--image {
  height: 12rem;
  border-radius: 2rem;
}
.logout_btn_container {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  margin-top: 1rem;
}

.modalAnswerNext_next_btn2 {
  align-self: flex-end;
  font-family: inherit;
  background-color: transparent;
  border: 1px solid var(--lightest-blue2);
  border-radius: 1rem;
  padding: 1rem 2rem;
  color: var(--white2);
  font-weight: 600;
  font-size: 1.4rem;
  opacity: 1;
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s;
}
.modalAnswerNext_next_btn2 span {
  height: 1px;
  width: 3rem;
  background-color: var(--white2);
}
.modalAnswerNext_next_btn3 {
  align-self: flex-end;
  font-family: inherit;
  background-color: var(--white2);
  border: 1px solid var(--lightest-blue2);
  border-radius: 1rem;
  padding: 1rem 2rem;
  color: var(--grey2);
  font-weight: 600;
  font-size: 1.4rem;
  opacity: 1;
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s;
}
.modalAnswerNext_next_btn3 span {
  height: 1px;
  width: 3rem;
  background-color: var(--grey2);
}
