.container {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  background-color: var(--bg-color2);
  display: grid;
  grid-template-columns: 16rem repeat(1, 1fr);
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease;

  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  line-height: 1.7;
}
.mainContent {
  grid-column: 2 / -1;
  overflow: hidden;
  transition: all 0.5s ease;
  margin: 1.5rem 0;
  margin-right: 1.5rem;
  padding-right: 1rem;
  background-color: var(--primary-color2);
  /* box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a; */
  border-radius: 2rem;
  position: absolute;
  left: 19rem;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 50%;
}
.content {
  margin-top: 1.5rem;
  height: calc(100vh - 6rem);
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-top: 2rem;
  color: var(--white2);
}

/* Header css */
.header {
  margin-top: 3rem;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.header__title {
  font-size: 2rem;
  font-weight: 200;
  line-height: 1;
  color: var(--white2);
  text-transform: uppercase;
}
.goBackBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.goBackBtn_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white2);
}
.add {
  margin-left: 1.5rem;
}
.addIcon {
  width: 2.6rem;
  height: 2.6rem;
  fill: var(--primary-color2);
  cursor: pointer;
}
.custom-classname.react-toggle--checked .react-toggle-track {
  background-color: red;
}
.therapistView {
  position: absolute;
  right: 10%;
  display: flex;
  align-items: center;
  gap: 2rem;
  color: white;
  font-size: 1.6rem;
}
