.modal {
	position: fixed;
	display: block;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	overflow-x: hidden;
	background-color: rgba(31, 32, 41, 0.75);
	pointer-events: none;
	opacity: 0;
	transition: opacity .0s ease;
}

.visible {
	pointer-events: auto;
	opacity: 1;
	transition: all 300ms ease-in-out;
}

.modal__wrap {
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	display: block;
	width: 60vw;
	/* height: 60vh; */
	margin: 0 auto;
	border-radius: 2rem;
	padding: 4rem 5rem;
	background-color: var(--white);
	align-self: center;
	box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
	opacity: 0;
	transform: scale(0.6);
	transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
	transform: scale(0);
}

.visible .modal__wrap {
	opacity: 1;
	transform: scale(1);
	transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}

@media (max-width: 500px) {
	.modal__wrap {
		padding: 2rem 3rem;
		width: 85vw;
	}
}
