.emogi_container{
    display: flex;
    background-color: var(--gray);
    width: fit-content;
    padding: 0 1rem;
    border-radius: 1rem;
    box-shadow: 3px 3px 10px #b8b9be, -3px -3px 10px #fff;
    overflow: hidden;
    align-items: center;
    margin: 1rem 0 2rem 0;
}

.emogi_container .emogi_item{
    padding: 2rem;
    cursor: pointer;
    border-radius: 1rem;
    transition: all .3s;
}
.emogi_container .emogi_item:hover{
    padding: 1rem;
}

.emogi_container .emogi_item p{
    font-size: 2rem;
    transition: all .3s;

}

.emogi_container .emogi_item:hover p{
    font-size: 3rem;
}