/* .profile {
	display: grid;
	gap: 1rem;
	grid-template-columns: 50% 25%;
	grid-template-rows: 1fr 6rem;
}
.profile__personal {
	grid-column: 1/2;
	grid-row: 1/2;
} */
.profile__heading {
  /* margin-bottom: .5rem; */
  font-size: 1.7rem;
  font-weight: 600;
  color: var(--primary);
  margin-bottom: 2rem;
}

.profile__subscription_text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #313e76;
  margin-top: 1rem;
}

.profile__subscription_color {
  color: black;
}
.profile__name {
  font-size: 2rem;
  font-weight: 500;
}

.profile__contactInfo--heading {
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--dark-grey);
}
.profile__contactInfo--contact {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 1rem 0;
}
.profile__contactInfo--contactIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--secondary);
}
.profile__contactInfo--contactName {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--dark-grey);
}
.profile__contactInfo--contactAddress {
  font-size: 1.2rem;
  font-weight: 600;
  font-style: italic;
  color: var(--dark-grey);
}
.profile__password {
  width: 30vw;
}

.profile__content {
  background-color: var(--white);
  border-radius: 1rem;
  padding: 2rem 3rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.myprofile__input {
  background-color: var(--gray);
  border: 1px solid var(--primary);
  padding: 0.5rem 1rem;
  box-shadow: var(--box-shadow-input);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.myprofile__input--label {
  font-size: 1.2rem;
  color: var(--light-dark);
}
.myprofile__input--text {
  border: none;
  font-family: inherit;
  font-size: 1.2rem;
  outline: none;
  background-color: transparent;
  width: 100%;
}
.myprofile__input--text::-webkit-input-placeholder {
  color: var(--placeholder);
}
.myprofile__input--iconBox {
  background-color: var(--primary);
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}
.myprofile__input--icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
}
.myprofile__input--button {
  border: none;
  font-family: inherit;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  color: var(--white);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.4s;
}
.myprofile__input--button:hover {
  background-color: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);
}
.profile__content2 {
  grid-column: 1/2;
  grid-row: 2/-1;
  background-color: var(--gray);
  border-radius: 1rem;
  padding: 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.profile__input {
  background-color: var(--bg-primary);
  border: 1px solid var(--primary);
  padding: 0.5rem 1rem;
  box-shadow: var(--box-shadow-input);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.profile__button {
  margin-top: 2rem;
  border: 1px solid var(--primary);
  border-radius: 1rem;
  background-color: var(--primary);
  font-family: inherit;
  font-size: 1.3rem;
  align-self: flex-start;
  padding: 0.5rem 2rem;
  color: var(--white);
  box-shadow: var(--box-shadow-input);
  cursor: pointer;
  transition: all 0.4s;
}
.profile__button:hover {
  border: 1px solid var(--primary);
  background-color: transparent;
  color: var(--primary);
}
.profile__button--password {
  margin-top: 2rem;
  border: 1px solid var(--red);
  border-radius: 1rem;
  background-color: var(--red);
  font-family: inherit;
  font-size: 1.3rem;
  align-self: flex-start;
  padding: 0.5rem 2rem;
  color: var(--white);
  box-shadow: var(--box-shadow-input);
  cursor: pointer;
  transition: all 0.4s;
}
.profile__button--password:hover {
  border: 1px solid var(--red);
  background-color: transparent;
  color: var(--red);
}
