.container {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 8rem;
}
.heading {
  font-family: 'Sarina';
  font-style: normal;
  font-weight: 400;
  font-size: 3.6rem;
  line-height: 6rem;
  color: #ffffff;
}
.content{
    width: 100%;
    height: 90%;
 
    color: #ffffff;
    font-size: 1.4rem;
  
    padding:1rem;
    overflow-y: scroll;
}