.input_container {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.input_container_label {
  font-size: 1.4rem;
  color: var(--light-grey);
  font-weight: 500;
}

.input_box_stripe {
  background-color: var(--white2);
  padding: 1rem 0;
  padding-left: 1rem;
}

.input_box {
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: var(--white2);
  padding-left: 1rem;
}

.input_type {
  width: 100%;
  padding: 1rem 0;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--dark-grey2);
}

.input_type::placeholder {
  color: var(--light-grey);
}

.input_error {
  color: var(--error2);
  font-size: 1.4rem;
  font-weight: 500;
}

.checkBtn {
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
  color: var(--light-grey);

  display: flex;
  align-items: center;
  gap: 1.5rem;
  cursor: pointer;
}

.checkBtn svg {
  height: 2rem;
  width: 2rem;
  fill: var(--light-grey);
}

.button,
.button_active {
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 1rem 3rem;
  border-radius: 10rem;
  cursor: pointer;
  transition: all 0.4s;

  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: var(--button-green2);
  border: 1px solid var(--button-green2);
  color: var(--white2);
}

.button_active {
  background-color: transparent;
  border: 1px solid var(--button-green2);
  color: var(--button-green2);
}

.button_icon {
  height: 1.8rem;
  width: 1.8rem;
  transition: all 0.4s;
}

.button_active .button_icon {
  fill: var(--button-green2);
}

.button .button_icon {
  fill: var(--white2);
}

.button:hover {
  background-color: transparent;
  color: var(--button-green2);
}

.button:hover .button_icon {
  fill: var(--button-green2);
}

.button:disabled {
  background-color: var(--light-grey);
  color: var(--white2);
  border: 1px solid var(--light-grey);
}

.button_active:disabled {
  cursor: not-allowed;
}

.button:disabled:hover {
  background-color: var(--light-grey);
  color: var(--white2);
  border: 1px solid var(--light-grey);
  cursor: not-allowed;
}

/* Plans */
.plan,
.plan_active {
  box-shadow: 0px 2px 3rem 5px rgba(30, 112, 152, 0.1);
  border-radius: 1rem;
  padding: 2rem 3rem;
  width: 30vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s;
}

.signUpplan,
.signUpplan_active {
  box-shadow: 0px 2px 3rem 5px rgba(30, 112, 152, 0.1);
  border-radius: 1rem;
  padding: 2rem 3rem;
  width: 60vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s;
}

.plan {
  background-color: rgba(255, 255, 255, 0.3);
}

.plan_disabled .coveredText {
  font-size: 1.8rem;
  margin-top: 0.8rem;
  color: #6a9045;
  font-weight: 700;
  opacity: 1 !important;
  z-index: 2;
  top: 20rem;
  position: absolute;
}

.plan_active {
  background-color: rgba(186, 231, 253, 0.3);
}

.plan_heading {
  font-family: 'Segoe';
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--dark-blue3);
  text-align: center;
}

.plan_price {
  font-size: 1.5rem;
  font-weight: 800;
  color: var(--dark-blue3);
  text-transform: uppercase;
  font-style: italic;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.plan_desc {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--light-grey);
  text-transform: uppercase;
  font-style: italic;
  margin-bottom: 0.5rem;
  text-align: center;
}

.plan_text_bold {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--dark-blue3);
  margin-bottom: 0.5rem;
}

.plan_texts {
  list-style: disc;
  margin-bottom: 2rem;
}

.plan_texts li {
  font-size: 1.3rem;
  font-weight: 400;
}

/* color: var(--dark-blue3); */

/* Payment Conf Modal */
.modalContainer {
  display: block;
  pointer-events: auto;
  background-color: rgba(159, 203, 230, 0.3);
  -moz-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  backdrop-filter: blur(2px);
}

.modal {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 1.1s ease-out;
  opacity: 1;
  visibility: visible;
  width: 40vw;
  min-height: 25vh;
  color: var(--white2);
  /* padding: 1rem 2rem; */
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.loaderText1 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;

  color: #ffffff;
}

.confirmCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmCard {
  width: 30vw;
  background-image: linear-gradient(to right top, rgba(159, 203, 230, 0.5), rgba(255, 255, 255, 0.8), rgba(159, 203, 230, 0.5));
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 2rem 3rem;

  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
}

.confirmCardHeader {
  font-weight: 600;
  font-size: 2rem;
  color: var(--dark-grey2);
  margin-top: 1rem;
}

.confirmCardText {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: var(--dark-grey2);

  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.confirmCardTextPrice {
  font-weight: 600;
  color: var(--dark-blue3);
}

.confirmCardTextPrice_striked {
  font-weight: 600;
  color: var(--light-grey);
  font-size: 1.2rem;
  text-decoration-line: line-through;
}

.plan_disabled{
  opacity: 1;
  z-index: 0;
  box-shadow: 0px 2px 3rem 5px rgba(30, 112, 152, 0.1);
  border-radius: 1rem;
  padding: 2rem 3rem;
  width: 30vw;
  background-color: #f0f0f0; ;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s;
}
  
.plan_disabled::after {
  content: "";
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f0f0f0d1!important;
  z-index: 1;
}

.confirmCardDesc {
  margin-top: 1.5rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--dark-blue3);
}

.confirmCardButtonClose {
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  color: #ee9426;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.iconButton {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.iconButton_icon {
  height: 2rem;
  width: 2rem;
  fill: var(--dark-blue3);
}

@media (max-width: 1400px) {

  .confirmCard,
  .modal {
    width: 40vw;
  }
}

@media (max-width: 1000px) {

  .plan,
  .plan_active,
  .confirmCard,
  .modal {
    width: 60vw;
  }
    .plan_disabled{
    width: 100%;
    margin: 2rem;
  }
}

@media (max-width: 867px) {

  .plan,
  .plan_active,
  .confirmCard,
  .modal {
    width: 70vw;
  }
  .plan_disabled{
    width: 100%;
  }
}

@media (max-width: 500px) {

  .plan,
  .plan_active,
  .confirmCard,
  .modal {
    width: 90vw;
  }

  .confirmCard {
    padding: 2rem 1.5rem;
  }
  .plan_disabled{
    width: 100%;
  }
}

@media (max-width: 280px) {

  .content_box,
  .confirmCard {
    padding: 2rem 1rem;
  }

  .plan_disabled{
    width: 100%;
  }
}

@media (max-width: 1020px) {

  .signUpplan,
  .signUpplan_active {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .signUpplan,
  .signUpplan_active {
    width: 90vw;
  }

  .plan_disabled{
    width: 100%;
  }

  .signupParaTitle {
    padding: 5rem 1rem 1.5rem 0rem;
  }
  
}