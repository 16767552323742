.onboard_cirlce_progress_container {
  position: absolute;
  bottom: 6rem;
  left: 50%;
  display: flex;
  gap: 2rem;
}
.onboard_cirlce_progress_circle {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  background: #ffffff;
}
.circle_active {
  background: #e56e00;
}

@media (max-width: 575.98px) {
  .onboard_cirlce_progress_container {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    display: none;
    gap: 1rem;
  }
}

@media (min-width: 575px) and (max-width: 767.98px) {
  .onboard_cirlce_progress_container {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    display: none;
    gap: 1rem;
  }
}

@media (min-width: 767px) and (max-width: 991.98px) {
  .onboard_cirlce_progress_container {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    display: flex;
    gap: 1rem;
  }
}

/* .onboard_container {
  width: 100vw;
  height: 100vh;
  background-color: var(--primary);
  padding: 3rem 3rem 0 3rem;
  transition: all 1s;
}
.onboard_subcontainer {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  background-color: var(--gray);
  overflow: hidden;
}
.onboard_main_container {
  padding-top: 1rem;
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
}
.onboard_scroll_container {
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  position: relative;
  padding: 5rem 5rem 5rem 5rem;
  height: 100%;
}

.onboard_bx_logo {
  width: 9rem;
  height: 11rem;
}
.onboard_card {
  margin-top: 4rem;
  width: 85%;
  border-radius: 2rem;
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
}
.onboard_card_first {
  margin-top: 0rem;
  position: sticky;
}

.onboard_card_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2em 4rem;
  cursor: pointer;
}
.onboard_card_content {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
}
.onboard_card_active {
  display: block;
}
.onboard_card_hide {
  display: none;
}
.onboard_card_logo {
  height: 2.4rem;
  width: 2.4rem;
  fill: var(--primary);
}
.onboard_card_logo_2 {
  width: 1.6rem;
  height: 1.8rem;
}
.onboard_card_logo_3 {
  width: 1.6em;
  height: 1.8rem;
  transform: scale(1.3);
}
.onboard_card_logo_4 {
  width: 1.6em;
  height: 1.8rem;
}
.onboard_card_logo_5 {
  width: 8rem;
  height: 8rem;
}
.onboard_card_header_left {
  display: flex;
  flex-direction: row;
  color: var(--primary);
  font-weight: 600;
  font-size: 1.8rem;
}
.rotate_arrow {
  transform: rotate(-180deg);
}

.onboard_card_line {
  width: 97%;
  margin: 0 auto;
  border: 0.1rem solid #e5e5e5;
}
.onboard_card_content_container {
  margin-top: 2.8rem;
  display: flex;
  flex-direction: row;
}

.onboard_card_content_left {
  margin-left: 1.5rem;
  width: 50%;
}
.onboard_card_content_left_row {
  display: flex;
  justify-content: space-between;
}
.onboard_card_content_left_row2 {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.onboard_card_input {
  width: 28rem;
  height: 5rem;
  display: flex;
  align-items: center;
  background-color: var(--gray);
  border-radius: 1rem;
  padding-left: 1.5rem;
}
.onboard_card_input_line {
  width: 2.1rem;
  height: 0;
  border: 0.1rem solid #000000;
  transform: rotate(90deg);
  margin-left: 0.5rem;
}
.onboard_card_input_text {
  width: 100%;
  border: none;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 100%;
  background-color: var(--gray);
  border-radius: 1rem;
}

.onboard_card_input_text::placeholder {
  color: var(--placeholder);
}
.onboard_card_content_right {
  margin-right: 3rem;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textArea {
  align-items: flex-start;
}
.onboard_card_input_area {
  width: 50rem;
  display: flex;
  align-items: flex-start;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  background-color: var(--gray);
  border-radius: 1rem;
  margin-top: 2rem;
}
.onboard_card_input_text2 {
  width: 35rem;
  resize: none;
  border: none;
  outline: none;
  height: 8rem;
  padding-left: 1rem;
  border-radius: 1rem;
  background-color: var(--gray);
}
.line2 {
  margin-top: 1rem;
}
.onboard_upload_image {
  width: 40rem;
  height: 20rem;
  background-color: var(--gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
  overflow: hidden;
}
.onboard_card_upload_image {
  width: 28%;
  height: 50%;
}
.onboard_upload_text {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #aa9999;
}
.onboard_button_container {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.5rem;
}
.onboard_button_next {
  width: 5.2rem;
  height: 4.2rem;
  background-color: var(--primary);
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.onboard_card_module_text {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--primary);
  margin-top: 2rem;
  padding-left: 1.5rem;
}
.onboard_module_card_content {
  margin-top: 4rem;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.onboard_description_card_container {
  margin-top: 4rem;
  width: 100%;
}
.onboard_payment_card_conatainer {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  margin-left: 2rem;
  width: 76.8rem;
  background: var(--gray);
  box-shadow: 1px 2px 8px rgba(146, 146, 146, 0.5);
  border-radius: 3rem;
  padding: 1.7rem 2.5rem;
}
.amount {
  height: 100%;
}
.onboard_credit_card_row3 {
  display: flex;
  justify-content: space-between;

  margin-right: 10rem;
}
.oboard_credit_card_line {
  width: 2.24rem;
  height: 0;

  border: 0.1rem solid #000000;
  transform: rotate(110.28deg);
}
.oboard_credit_card_details {
  display: flex;
}
.onboard_credit_card_dates {
  display: flex;
  align-items: center;
}
.onboard_credit_card_month {
  width: 3rem;
  border: none;
  outline: none;
  resize: none;
}
.oboard_credit_card_cvc {
  display: flex;
  align-items: center;
  margin-left: 3rem;
}
.oboard_credit_card_cvc_input {
  width: 3rem;
  border: none;
  outline: none;
  resize: none;
}

.onboard_credit_card_month::placeholder,
.oboard_credit_card_cvc_input::placeholder {
  color: black;
}

.onboard_submit_button {
  margin-top: 3rem;
  display: flex;
}
.onboard_button_submit {
  width: 13.4rem;
  height: 4.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  border-radius: 1rem;
  cursor: pointer;
  color: var(--light-white);
  font-size: 1.5rem;
  outline: none;
  border: none;
  appearance: none;
}
.onboard_error {
  color: var(--red);
  font-size: 1rem;
  margin-left: 1rem;
}
.onboard_inpit_error_container {
  display: flex;
  flex-direction: column;
}
.stripe_card_container {
  margin-top: 1.5rem;
  margin-left: 1.5rem;
}
.onboard_card_select {
  outline: none;
  border: none;
  font-family: inherit;
  font-size: 1.35rem;
  letter-spacing: 0.1px;
  font-weight: 400;
  width: 100%;
  color: #999;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../../../Assets/Images/down-arrow.png');
  background-repeat: no-repeat;
  background-position: right 1rem top 0.4rem;
  background-size: 1em;
  outline: none;
  display: inline-block;
  margin-left: 10px;
  background-color: var(--gray);
} */
