.properties_card_container {
  background-color: #f5f4f4;
  box-shadow: 0 0.3rem 1rem 0.3rem rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  padding: 3rem;
  transition: all 0.3s ease;
  /* width: 35rem; */
}
.properties_card_container:hover {
  transform: scale(1.03);
  /* transform: translate(.2rem, -.3rem); */
}
.properties_card_head {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-grey);
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
}
.properties_card_header {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.client_card_color {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 600;
}
.circularColor {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
}
.properties_card_videos__title {
  font-weight: 600;
  font-style: italic;
  color: var(--primary);
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
}
.properties_card_videos__list {
  display: flex;
  gap: 1rem;
  align-items: center;
  overflow: auto;
  width: 34vw;
  padding-bottom: 2rem;
}
.properties_card_videos__list--modalVideos {
  margin-left: 3rem;
}
.properties_card_videos__list_heading_search {
  display: flex;
  gap: 0rem;
  align-items: center;
  margin-bottom: 1rem;
}
.properties_card_videos__list--modal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  height: 30vh;
  padding-right: 1rem;
  overflow: auto;
}
.properties_card_videos__list--preassetmodal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  height: 40vh;
  padding-right: 1rem;
  overflow-x: hidden;
}
.properties_card_video__title {
  font-size: 1.3rem;
  font-weight: 600;
  width: 185px;
}
.properties_card_videos__list--modalTitle {
  font-size: 1.4rem;
  font-weight: 600;
}
.properties_card_video__button {
  text-decoration: none;
  border: none;
  background-color: transparent;
  outline: none;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--secondary);
  cursor: pointer;
}
.properties_card_videos__list--videoSelect {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--gray);
  padding: 1rem;
  border-radius: 1rem;
  max-height: 20rem;
}
.properties_card_videos__list--modalBtn2 {
  font-size: 1.2rem;
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.4s;
}
.properties_card_videos__list--modalBtn {
  font-size: 1.2rem;
  color: var(--primary);
  background-color: transparent;
  border: 1px solid var(--primary);
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.4s;
}
.properties_card_videos__list--modalBtn:hover {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--white);
}
.properties_card_pdf_conatiner {
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
}

.properties_card_pdf {
  width: 10rem;
  height: 80%;
}
.properties_card_pdf2 {
  width: 45%;
  height: 80%;
}
