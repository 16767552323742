.mobile_sidebar_container {
  display: none;
  z-index: 100;
}

.mobile_sidebar_logo {
  display: none;
}

.mobile_sidebar_active_tab {
  display: none;
}

.mobile_sidebar_items {
  display: none;
}

.mobile_sidebar_active_tab .mobile_sidebar_menu_item_logo {
  display: none;
}

.mobile_sidebar_active_tab .mobile_sidebar_menu_item_logo2 {
  display: none;
}

.mobile_sidebar_menu_item_title {
  display: none;
}

.mobile_logout_link_container {
  display: none;
}

.hamburger_container {
  display: none;
}

.hamburger_mobile_menu_icon {
  display: none;
}

.mobile_sidebar_overflow {
  display: none;
  transition: all 0.5s ease;
}

@media (max-width: 769px) {
  .mobile_sidebar_container {
    background-color: var(--primary);
    position: absolute;
    width: 25rem;
    height: 100%;
    transition: all 0.5s ease;
    display: none;
    justify-content: space-between;
    flex-direction: column;
    padding: 1.5rem;
    overflow-y: auto;
  }

  .mobile_sidebar_logo {
    display: block;
    height: auto;
    width: 3rem;
    cursor: pointer;
  }

  .mobile_sidebar_active_tab {
    display: block;
    background-color: var(--white);
    color: var(--secondary) !important;
    box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.15);
  }

  .mobile_sidebar_items {
    text-decoration: none;
    color: var(--white);
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding: 0.5rem;
    transition: all 0.5s ease;
    border-radius: 0.4rem;
    width: 100%;
    cursor: pointer;
  }

  .mobile_sidebar_menu_item_logo {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--white);
  }

  .mobile_sidebar_menu_item_logo2 {
    display: none;
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--secondary);
  }

  .mobile_sidebar_menu_item_title {
    display: block;
    margin-left: 1.3rem;
    font-size: 1.2rem;
  }

  .mobile_logout_link_container {
    color: var(--secondary);
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding: 0.8rem;
    transition: all 0.5s ease;
    border-radius: 0.5rem;
    width: 100%;
    cursor: pointer;
  }

  .mobile_sidebar_active_tab .mobile_sidebar_menu_item_logo {
    display: none;
  }

  .mobile_sidebar_active_tab .mobile_sidebar_menu_item_logo2 {
    display: block;
  }

  .hamburger_mobile_menu_icon {
    display: block;
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    margin-right: 1rem;
    color: var(--white);
  }

  .hamburger_container {
    display: flex;
    margin: 2rem 0rem;
    justify-content: space-between;
    align-items: center;
  }

  .mobile_sidebar_overflow {
    width: 0;
  }
}

@media (max-width: 500px) {
  .mobile_sidebar_container {
    width: 20rem;
  }

  .mobile_sidebar_menu_item_title {
    display: block;
    margin-left: 1.3rem;
    font-size: 1rem;
  }
}
