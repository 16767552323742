.client_card_questionType {
	color: var(--primary);
	font-weight: 600;
	font-style: italic;
}
.properties_card_answers {
	font-size: 1.3rem;
	font-weight: 500;
}
.properties_card_answer {
	/* width: 2em; */
}
.properties_card_answer_right {
	background-color: var(--secondary);
	font-weight: 400;
	padding: .1rem 1rem;
	border-radius: 2rem;
	font-size: .8rem;
	color: var(--white);
	text-transform: capitalize;
}
.properties_card_answer_right_red {
	background-color: var(--red);
}
.properties_card_answer_right_green {
	background-color: var(--green);
}
.match_pair_left {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
