/* ############# Sidebar styles Started ############# */

.not_show_scrollbar {
  overflow-x: hidden;
}

.not_show_scrollbar::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.not_show_scrollbar::-webkit-scrollbar-track {
  background-color: var(--primary);
}

.scrollY::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}
.scrollY {
  scrollbar-width: none;
}

.sidebar_container {
  background-color: var(--primary);
  position: fixed;
  width: 25rem;
  height: 100%;
  transition: all 0.5s;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 3rem 0rem;
  z-index: 100;
}

.sidebar_container_active {
  width: 8rem;
  padding: 3rem 2.2rem;
}

.sidebar_logo {
  width: 9rem;
  margin: 0 auto 2rem auto;
  cursor: pointer;
}
.sidebar_logo_small {
  width: 4rem;
  margin: 1rem auto;
  cursor: pointer;
}

.sidebar_menu_items {
  text-decoration: none;
  color: var(--white);
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 0.8rem;
  transition: all 0.5s ease;
  border-radius: 0.5rem;
  width: 95%;
  cursor: pointer;
}

.sidebar_menu_items:hover {
  background-color: var(--white);
  color: var(--secondary);
}

.active_tab {
  background-color: var(--white);
  color: var(--secondary);
  transform: translateX(4rem);
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.15);
}

.active_tab .sidebar_menu_item_logo2 {
  display: block;
}

.active_tab .sidebar_menu_item_logo {
  display: none;
}

.sidebar_overflow {
  overflow: hidden;
}

.sidebar_menu_items:hover .sidebar_menu_item_logo {
  display: none;
}

.sidebar_menu_item_logo2 {
  display: none;
  height: 2.2rem;
  width: 2.2rem;
  fill: var(--secondary);
}

.sidebar_menu_items:hover .sidebar_menu_item_logo2 {
  display: block;
}

.sidebar_menu_item_logo {
  height: 2.2rem;
  width: 2.2rem;
  fill: var(--white);
}

.sidebar_menu_item_title {
  margin-left: 1.3rem;
  font-size: 1.5rem;
}

.logout_link_container {
  color: var(--secondary);
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 0.8rem;
  transition: all 0.5s ease;
  border-radius: 0.5rem;
  width: 100%;
  cursor: pointer;
}

@media (max-width: 769px) {
  .sidebar_container {
    width: 0;
    padding: 0;
  }

  .sidebar_container_active {
    width: 0;
    padding: 0;
  }

  .active_tab {
    transform: translateX(0);
  }

  .sidebar_menu_items {
    display: none;
  }

  .sidebar_logo {
    display: none;
  }

  .logout_link_container {
    display: none;
  }
}
