.about_container {
	display: flex;
	gap: 2rem;
}
.about_container_image {
	height: 10rem;
}
.aboutDescription {
	font-size: 1.5rem;
	font-weight: 600;
}
.about_container_short {
	font-size: 1.3rem;
	font-weight: 500;
}
.aboutLinks {
	border-top: 1px solid var(--light-blue2);
	margin-top: 2rem;
	padding-top: 1rem;
	display: flex;
	align-items: center;
	gap: 1rem;
}
.aboutLink {
	font-size: 1.3rem;
	font-weight: 600;
	text-decoration: underline;
	color: var(--white2);
	transition: all .4s;
}
.aboutLink:hover {
	color: var(--light-blue2);
}
