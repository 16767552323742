.algo_main_content{
    display: grid;
	grid-template-columns: repeat(1, minmax(min-content, 1fr));
    margin-top: 5rem;
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.algo_default_content{
    overflow: auto;
    height: auto;
    width: 100%;
}