.description {
  font-size: 1rem;
  width: 100%;
  height: 50%;
  font-weight: 400;
  overflow: auto;
}
.boxContainer {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.box {
  height: 2.5rem;
  background-color: var(--secondary);
  border-radius: 1.1rem;
  display: flex;
  align-items: center;
  color: var(--white);
  padding: 0.6rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-left: 0.8rem;
}
