.LAB_FAQ_container {
  padding-left: 1rem;
  transition: all 0.5s ease;
}
.LAB_FAQ_card {
  width: 90%;
  border-bottom: 1px solid #b6d7ff;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  transition: max-height 0.5s ease;
  max-height: 50rem;
}
.LAB_FAQ_collapse_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.LAB_FAQ_collapse_header_left {
  width: 95%;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #ffffff;
}
.LAB_FAQ_collapse_header_icon {
  width: 2.5rem;
  height: 2.5rem;
}
.LAB_FAQ_collpase_content {
  width: 95%;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #ffffff;
}
.down {
  transform: rotate(180deg);
}
.LAB_FAQ_card_skeleton {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.LAB_FAQ_collapse_header_skeleton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.LAB_FAQ_collapse_header_left_skeleton {
  height: 4rem;
}
.LAB_FAQ_collapse_header_right_skeleton {
  display: none;
  /* margin-left: 1.5rem;
  width: 2.5rem;
  height: 2.5rem; */
}
.LAB_FAQ_collpase_content_skeleton {
  margin-top: 1.5rem;
  height: 7rem;
}
