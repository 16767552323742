.small_custom_switch_container {
  height: 2.5rem;
  width: 4rem;
  background-color: var(--lightest-blue);
  border-radius: 5rem;
  transition: all 0.3s ease;
  overflow: hidden;

  cursor: pointer;
  display: flex;

  align-items: center;
}

.small_custom_switch_dot {
  height: 2rem;
  width: 2rem;
  background-color: var(--light-grey);
  border-radius: 5rem;
  transition: all 0.3s ease;
  margin-left: 0.3rem;
}
.small_custom_switch_dot_checked {
  transform: translateX(1.3rem);
  background-color: var(--primary);
}

.swicth_loader_container {
  height: 2.5rem;
  width: 4rem;
  background-color: var(--lightest-blue);
  border-radius: 5rem;
  transition: all 0.3s ease;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
