.chartContainer {
	height: 26vh;
	/* border-bottom: 1px solid rgba(182, 215, 255, 1); */
	margin-bottom: 5rem;
	position: relative;
	margin-left: 3rem;
}

.chart_main_container{

	margin-top: 2rem;
}

.chart_container{
	width: 80rem;
}

.dashboard_charts{
	height: 100%;
}

.charts_label{
	font-size: 2rem;
	font-weight: bold;
}

.chart_no_data{
	height: 35rem;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.chart {
	transform: translateY(3.5rem);
	height: 100%;
	width: 100%;
	padding: 0 2rem;
	display: flex;
	gap: 5rem;
	align-items: flex-end;
	position: absolute;
	z-index: 100;
	padding-bottom: 1rem;
	overflow-x: auto;
}

.chart__stack {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
}

.chart__stack p {
	font-size: 1.5rem;
	color: var(--secondary);
	width: max-content;
}

.chart__bar {
	display: flex;
	align-items: flex-end;
	gap: .2rem;
	border-radius: 1rem;
	overflow: hidden;
}

.incomplete_stack, .complete_stack {
	width: 8rem;
	display: flex;
	justify-content: center;
	transition: all .4s;
}

.complete_stack:hover {
	background-color: var(--primary-light);
}

.incomplete_stack:hover {
	background-color: var(--lightest-grey);
}

.stack_container {
	position: relative;
}

.user_stack {
	width: 3rem;
}

.incomplete_stack {
	background-color: var(--dark-white);
	z-index: 100;
	position: relative;
	border-radius: 1rem;
	min-height: .8rem;
}

.incomplete_stack span, .complete_stack span {
	font-size: 1.2rem;
	margin-top: -2rem;
}

.complete_stack {
	background-color: var(--primary);
	position: absolute;
	border-radius: 1rem;
	bottom: 0;
	min-height: .3rem;
}

.chartIndicator {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	gap: 1rem;
}

.date_main_contaner {
	position: relative;
}

.date_container {
	position: absolute;
	top: -3rem;
	right: 0;
	display: flex;
	gap: 1rem;
}

.date_input_container {
	display: flex;
	align-items: center;
}

.date_input_container p {
	text-transform: capitalize;
	font-size: 1.3rem;
	color: var(--primary);
	margin-right: 1rem;
}

.date_input_filter_container {
	background-color: var(--primary);
	box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	margin-left: .5rem;
	border-radius: .5rem;
	transition: all .5s ease;
}

.date_input_filter_container:hover {
	background-color: var(--primary-light);
}

.date_input_filter_img {
	height: auto;
	width: 3.5rem;
	padding: .5rem;
}

.date__input {
	outline: none;
	border: none;
	color: var(--primary);
	box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
	border-radius: 1rem;
	padding: .5rem;
	cursor: pointer;
}

.chartBottom {
	display: flex;
	gap: .5rem;
	align-items: center;
}

.indicator__android, .indicator__ios {
	height: 2rem;
	width: 2rem;
	border-radius: .5rem;
}

.indicator__android {
	background-color: var(--primary);
}

.indicator__ios {
	background-color: var(--dark-white);
}

#ticks {
	top: -19.8rem;
	width: 100%;
	z-index: 99;
}

#ticks .tick {
	position: relative;
	border-bottom: 1px dotted var(--light-grey);
}

#ticks .tick p {
	position: absolute;
	left: -4rem;
	top: 2.7rem;
	margin-left: 1rem;
	color: var(--secondary);
	font-size: 1.5rem;
}

.chart_loader_container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	align-items: center;
	display: flex;
	justify-content: center;
	z-index: 200;
}

.chart_loader {
	height: 10rem;
	width: 10rem;
}

.no_data_center {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 20%;
}