.custom_switch_container {
  height: 2.8rem;
  width: 5rem;
  background-color: var(--lightest-blue);
  border-radius: 5rem;
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: pointer;
}

.custom_switch_container_checked {
  background-color: var(--lightest-blue);
}

.custom_switch_dot {
  height: 2.7rem;
  width: 2.7rem;
  background-color: var(--light-grey);
  border-radius: 5rem;
  transition: all 0.3s ease;
}
.custom_switch_dot_checked {
  transform: translateX(2.2rem);
  background-color: var(--primary);
}

.swicth_loader_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: var(--gray);
}

.swicth_loader {
  /* margin: 0 1.2rem; */
  height: 2rem;
  width: 2rem;
}
