.tableContainer {
	width: 98%;
	margin-top: 2rem;
	margin-right: 5rem;

	border-radius: 1rem;
	box-shadow: 0.5rem 0.5rem 2rem 2px rgb(0 0 0 / 14%);
	height: 45vh;
	overflow-y: auto;
	overflow-x: auto;
}

.table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

th {
	position: sticky;
	top: 0;
	z-index: 100;
}

.table tr th {
	white-space: nowrap;
	background-color: var(--primary);
	color: var(--white);
	padding: 1rem;
	font-size: 1.3rem;
	font-weight: 600;
}

.table tr td {
	white-space: nowrap;
	padding: 1rem;
	color: var(--light-dark);
}

.table tr {
	transition: all 0.8s;
	border-bottom: 1px solid var(--gray);
	text-align: center;
	font-size: 1.2rem;
}
