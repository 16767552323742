.container {
  padding-left: 1rem;
}
.topContainer {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.bottomContainer {
  width: 90%;
}
.bar {
  max-height: 28rem;
}
.subContainer {
  width: 50%;
}
.bigheadsContainer {
  width: 11rem;
  height: 11rem;
}
.title {
  font-size: 1.8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 700;
}

.value {
  font-weight: 800;
}
.name {
  font-size: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.phone {
  display: flex;
  gap: 1.5rem;
  margin-top: 1.2rem;
}
.detailsContainer {
  padding-left: 1rem;
  font-weight: 700;
}
.icon {
  width: 2rem;
  height: 2rem;
  fill: white;
  color: white;
}
.text {
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.1rem;
}

.text2 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.1rem;
  margin-top: 1rem;
}

.line {
  width: 3.4rem;
  height: 0.1rem;

  background: #ffffff;
}
.buttonContainer {
  margin-top: 2rem;
  width: 20.4rem;
}
.button {
  width: 100%;
  padding: 1.2rem 0 1.2rem 0;
  background: rgba(182, 215, 255, 0.35);
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.imgContainer {
  height: 11rem;
}
.img {
  height: 100%;
  background-color: #ffffff;
  border-radius: 0.5rem;
}
.modalContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.8rem;
  text-align: center;

  color: #ffffff;
}
.modalInputContainer {
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
}
.input {
  display: flex;
  width: 24.8rem;
  background: #b6d7ff;
  padding-left: 1.8rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-right: 0.7rem;
  box-shadow: 0px 0px 5px 5px rgba(30, 112, 152, 0.46);
  border-radius: 1rem;
  align-items: center;
}
.inputIcon {
  width: 3rem;
  height: 3rem;
  fill: transparent;
}
.inputText {
  appearance: none;
  border: none;
  resize: none;
  outline: none;
  margin-left: 0.7rem;
  height: 100%;
  width: 100%;
  background: #b6d7ff;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #000000;
}
.inputText::placeholder {
  font-size: 1.5rem;
  color: var(--placeholder);
}
.modalButtonContainer {
  margin-top: 1.8rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
.skeletonContainer {
  margin-top: 0;
}
.skeletonTitle {
  width: 30rem;
  height: 2rem;
  margin-top: 1rem;
}
.skeletonName {
  width: 30rem;
  height: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.skeletonPhone {
  width: 30rem;
  height: 2rem;
  margin-top: 1.2rem;
}
.skeletonButton {
  margin-top: 2rem;
  width: 20.4rem;
  height: 5rem;
}

.errorMessage {
  color: var(--red);
  font-size: 1.4rem;
}
.button2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.textButton {
  align-self: center;
  margin-top: 1rem;
  cursor: pointer;
}
.header {
  font-size: 2rem;
  margin-top: 5rem;
}
.verfiyPasswordContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
