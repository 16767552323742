.cardContainer {
  background-color: #f5f4f4;
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 32px;
}
.container_title {
  display: flex;

  justify-content: space-between;
}
.container_title_left {
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: capitalize;
  display: inline;
  width: 70%;
}
.container_title_right {
  display: flex;
}
.descriptionContainer {
  margin-top: 5px;
}
.description {
  margin-top: 5px;
  background-color: #04da00;
  border-radius: 5px;
  font-family: Poppins;
  font-style: italic;
  font-weight: 500;
  font-size: 1rem;
  padding: 2px 4px 2px 4px;
  color: var(--white);
}
.line {
  margin-top: 12px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.25);
}
.danger {
  background-color: #ff0000;
}
.videoContainer {
  margin-top: 10px;
}
.pdfContainer {
  width: 250px;
  height: 120px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdfContainer a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdfImage {
  width: 60%;
  height: 100%;
}
