.addBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.addBtnIcon {
  height: 3rem;
}
.answer {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.answer_textBox {
  padding: 1rem 2rem;
  width: 18vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray);
}
.answer_textBox2 {
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray);
}
.answer_text {
  font-size: 1.3rem;
  color: var(--dark-grey);
  font-weight: 500;
}
.answer_text_input {
  font-family: inherit;
  border: none;
  border-bottom: 1px solid var(--primary);
  background-color: transparent;
  width: 100%;
  font-size: 1.2rem;
  outline: none;
}
.answer_text_icon {
  height: 2rem;
  cursor: pointer;
}
.answer_right {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.answer_right_icon {
  height: 2.5rem;
}
.answer_right_text {
  color: var(--green);
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
}
.answer_makeright_text {
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--primary);
  cursor: pointer;
}
.right_wrong_answer_textBox {
  padding: 0.7rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray);
  /* border-radius: 1rem; */
}
.rightWrongText {
  font-size: 1.7rem;
  text-align: center;
}

.back_button {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  text-transform: capitalize;
  gap: 0.5rem;
  cursor: pointer;
  width: fit-content;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  transition: all 0.4s;
  font-weight: 500;
  background-color: var(--white);
}

.back_button:hover {
  background-color: var(--lightest-grey);
}

.emogi_wrapper {
  padding: 1rem;
  border-radius: 50%;
  cursor: pointer;
}

.emogi_wrapper p {
  font-size: 2rem;
}

.edit_emogi {
  cursor: pointer;
  font-size: 1.5rem;
}
