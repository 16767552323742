.support {
  position: absolute;
  bottom: 4rem;
  right: 4rem;
  color: var(--grey2);
  font-size: 2.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon {
  width: 2.8rem;
  height: 2rem;
  fill: var(--grey2);
}
.text {
  margin-left: 1rem;
}
