.pagination {
  display: flex;
  font-size: 1.8rem;
}
.pagination > .active > a {
  border-radius: 0.5rem;
  background-color: var(--primary-light);
  color: #ffffff;
}
.pagination > .active > a:hover {
  border-radius: 0.5rem;
  background-color: var(--primary);
  color: #ffffff;
}

.pagination > li > a {
  background-color: #e5e5e5;
  padding: 1rem 2rem;
  display: block;
  text-decoration: none !important;
  transition: background-color 0.3s;
  cursor: pointer;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.pagination > li > a:hover {
  background-color: var(--light-grey);
}

.pagination > .active > a,
.pagination > .active > a:hover {
  box-shadow: none;
}

.pagination > .disabledClassName {
  /* display: none; */
}
.pagination > .previousClassName > a {
  background-color: var(--primary-light);
  color: #ffffff;
}
.pagination > .previousClassName > a:hover {
  background-color: var(--primary);
}
.pagination > .nextClassName > a {
  background-color: var(--primary-light);
  color: #ffffff;
}
.pagination > .nextClassName > a:hover {
  background-color: var(--primary);
}

ul {
  list-style: none;
}
