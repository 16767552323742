/* General Styles for SignUpPlan */
.signupPara {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0rem 1rem 3rem 11rem;
}

.signupParaTitle {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 3rem 1rem 1.5rem 11rem;
}

.signupBadgeParaTitle {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 6rem 14rem 1.5rem 0rem;
}

.conclusionParaTitle {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 2rem 0rem 1.2rem;
  text-align: center;
}

.conclusionParaTitle1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1D6F97;
  text-align: center;
}

.signupBadgeParaTitle1 {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0rem 14rem 1.5rem 0rem;
}

/* Slider Section */
.sliderSection {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  margin-top: -1.6rem;
  justify-content: center;
}

.sliderSectionTitle {
  font-size: 2rem;
  display: block;
  text-align: center;
  font-weight: 600;
  color: #1D6F97;
}

.quoteSlider {
  text-align: center;
  margin: 1rem auto;
  width: 80%;
  overflow: hidden; /* Hides content outside the visible area */
}

.quoteSlide {
  flex: 0 0 100%;
  border-radius: 8px;
}

/* Quote Container with Sliding Animation */
.quoteContainer {
  padding: 12px 0px;
  border-radius: 8px;
  gap: 0.4rem;
  display: flex;
  justify-content: center;
  width: 100%;
  transition: transform 0.2s ease-out; 
  transform: translateX(0); 
}

.quoteContainer.active {
  transform: translateX(0);
}

.quoteContainer.exit {
  transform: translateX(100%);
}

.quoteText {
  font-size: 1.5rem;
  color: #000000;
  font-weight: 500;
  margin-bottom: 10px;
}

.quoteImg {
  left: -2rem;
  top: 5.7rem;
  height: 4REM;
  margin-top: -1rem;
}

.quoteBy {
  font-size: 1.3rem;
  margin-left: -8rem;
  font-weight: 600;
  color: #000000;
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #1D6F97;
}

.unlockbadgeImage {
  height: 28rem;
}

.signUpBadge {
  position: absolute;
  left: -2.6rem;
  top: -2.3rem;
}

.badgeSection {
  display: flex;
  width: 100%;
  padding: 1rem 4rem;
  margin-top: -3rem;
}

.signUpBadgeText {
  position: absolute;
  text-transform: uppercase;
  left: 1.6rem;
  font-weight: 600;
  color: white;
  top: 6.4rem;
  font-size: 1.6rem;
  transform: rotate(-45deg);
}





@media (max-width: 2600px) {
  .quoteContainer{
    height: 12.8vh;
  }
}

@media (max-width: 1700px) {
  .quoteContainer{
    height: 16.8vh;
  }
}

@media (max-width: 1600px) {
  .quoteContainer{
    height: 20.8vh;
  }
}
@media (max-width: 1300px) {
  .quoteContainer{
    height: 26vh;
  }
}



@media (max-width: 1280px) {
  .quoteContainer{
    height: 23.8vh;
  }
  .quoteImg {
    display: none;
   }
   .sliderSection {
    margin-top: -0.6rem;
   }
  .dot {
    height: 12px;
    background-color: #9f9f9fc6;
  }
  .signupBadgeParaTitle {
      padding: 3rem 0rem 1.5rem 0rem;
  }
  .signupBadgeParaTitle1 {
    padding: 0rem 0rem 1.5rem 0rem;
  }
  .quoteBy {
    margin-left: 0rem;
  }
}

@media (max-width: 1200px) {
  .quoteContainer{
    height: 35.8vh;
  }
  .quoteImg {
    display: none;
   }
}

@media (max-width: 1024px) {
  .quoteContainer{
    height: 12vh
  }
}

@media (max-width: 1020px) {
  .quoteContainer{
    height: 18vh;
  }
  .badgeSection {
    display: flex;
    flex-direction: column;
    padding: 0rem 0rem;
  }
  .unlockbadgeImage {
    display: none;
  }
  .signupBadgeParaTitle {
    font-size: 1.3rem;
    font-weight: 500;
    padding: 3rem 0rem 1.5rem 0rem;
  }

  .signupBadgeParaTitle1 {
    font-size: 1.3rem;
    font-weight: 500;
    padding: 0rem 0rem 2.5rem 0rem;
  }

  .quoteImg {
   display: none;
  }
  
}

@media (max-width: 1000px) {

  .quoteContainer{
    height: 16vh
  }
}

@media (max-width: 820px) {

  .quoteContainer{
    height: 16vh
  }

  .signUpplan,
  .signUpplan_active {
    width: 90vw;
  }

  .signupParaTitle {
    padding: 8rem 1rem 1.5rem 0rem;
  }
  .signupPara {
    padding: 0rem 1rem 3rem 0rem;
  }
  .signupBadgeParaTitle {
    font-size: 1.3rem;
    font-weight: 500;
    padding: 3rem 0rem 1.5rem 0rem;
  }
  .badgeSection {
    display: flex;
    flex-direction: column;
    padding: 0rem 0rem;
  }

  .unlockbadgeImage {
    height: auto;
  }

  .signupBadgeParaTitle1 {
    font-size: 1.3rem;
    font-weight: 500;
    padding: 0rem 0rem 2.5rem 0rem;
  } 
  
}
@media (max-width: 809px) {

  .quoteContainer{
    height: 16vh;
  }
  .plan_disabled{
    width: 100%;
    margin: 2rem;
    
  }
}

@media (max-width: 600px) {
  .quoteContainer{
    height: 31vh;
  }
  .plan_disabled{
    width: 100%;
    margin: 2rem;
  }
}


/* @media (max-width: 900px) {

} */