.container {
  /* overflow-y: scroll; */
}
.header {
  /* margin-top: 2rem; */
  font-size: 2rem;
  text-transform: capitalize;
}

.hint {
  font-size: 1.5rem;
}

.graph {
  width: 100%;
  height: 35vh;
  margin-bottom: 1rem;
}

.bar {
  width: 60%;
  max-height: 30rem;
}

.mission_dropdown_select {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid var(--white);
  border-radius: 1rem;
  font-family: inherit;
  padding: 0.5rem 1rem;
  outline: none;
}

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loaderContainer {
  width: 100%;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom {
  margin-bottom: 5rem;
}

.errorContainer {
  width: 100%;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorMessage {
  font-size: 2.4rem;
  color: #fff;
}

.iconLeft {
  width: 3.3rem;
  height: 5.4rem;
  transform: rotate(180deg);
}

.icon {
  width: 3.3rem;
  height: 5.4rem;
}
.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3rem;
}
.title {
  text-align: center;
  font-size: 1.2rem;
  color: #fff;
}
