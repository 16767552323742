/* ############# Login page styles started ############# */

.login_container {
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, 1fr));
  height: 100vh;
  background-color: var(--primary);
}

.logo_form_container {
  grid-column: 1 / 2;
  background-color: var(--primary);
  overflow: hidden;
}

.login_form_container {
  grid-column: 2 / -1;
  border-radius: 3rem 0 0 3rem;
  overflow: hidden;
  padding: 2rem;
  background-color: var(--white);
}

.child_background_image {
  width: 60%;
  height: auto;
  border-radius: 2rem;
}

.login_logo_image {
  width: 20%;
  height: auto;
}

.login_text {
  font-size: 3.7rem;
  color: var(--white);
  text-transform: capitalize;
  margin-top: 3rem;
}

.to_your_account_text {
  font-size: 2.5rem;
  color: var(--white);
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0.5rem;
}

.login_input_container {
  width: 100%;
  padding: 0 20rem;
  margin-top: 5rem;
}

.login_on_boarding {
  margin-top: 1rem;
}
.login_on_boarding:hover {
  cursor: pointer;
}
.login_on_boarding_link {
  text-decoration: none;
  border: none;
  background-color: transparent;
  outline: none;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--primary);
  cursor: pointer;
}

@media (max-width: 1400px) {
  .login_input_container {
    width: 100%;
    padding: 0 10rem;
    margin-top: 5rem;
  }
}
@media (max-width: 1000px) {
  .login_input_container {
    width: 100%;
    padding: 0 5rem;
    margin-top: 5rem;
  }
}

@media (max-width: 769px) {
  .login_text {
    font-size: 2.2rem;
  }

  .to_your_account_text {
    font-size: 1.2rem;
    margin-top: 0.2rem;
  }

  .login_logo_image {
    width: 7.5rem;
    height: auto;
  }

  .login_form_container {
    padding: 0rem;
  }
}

@media (max-width: 600px) {
  .login_container {
    grid-template-columns: repeat(1, minmax(100%, 1fr));
  }
  .login_form_container {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    border-radius: 0;
    padding: 5rem;
  }
  .login_input_container {
    width: 100%;
    padding: 0rem;
    margin-top: 1rem;
  }

  .login_text {
    font-size: 2rem;
    display: none;
  }

  .to_your_account_text {
    display: none;
    font-size: 1.5rem;
  }

  .child_background_image {
    width: 60%;
    height: auto;
    border-radius: 2rem;
    display: none;
  }

  .logo_form_container {
    background-color: var(--primary);
    overflow: hidden;
    grid-row: 2 / -1;
  }

  .login_logo_image {
    width: 30%;
    height: auto;
  }
}
/* ############# Login page styles End ############# */
