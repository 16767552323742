.checkBox {
  width: 2rem;
  height: 2rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: var(--primary);
  border-width: 3px;
  border-style: solid;
}
.icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--secondary);
}
.checkBox:hover {
  cursor: pointer;
}
