.container {
  display: grid;
  grid-template-columns: 16rem repeat(1, 1fr);
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  line-height: 1.7;
}
.login_container {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  background-color: var(--primary-color2);
  background-image: linear-gradient(to right top, rgba(159, 203, 230), rgba(255, 255, 255), rgba(159, 203, 230));
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  gap: 3rem;
  grid-template-columns: 10rem repeat(7, 1fr);
  /* grid-template-rows: 27% 46% 27%; */
  grid-template-rows: repeat(2, 1fr);
}

/* changes */

.login_container_cards {
  grid-column: 2/6;
  /* grid-row: 2/3; */
  grid-row: 1/-1;
  align-self: center;
  /* background-color: white; */

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 3rem;
}
.login_container_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  box-shadow: 0px 2px 3rem 5px rgba(49, 62, 118, 0.1);
  /* border: 1px solid rgba(49, 62, 118, 0.49); */
  color: var(--dark-blue3);
  line-height: 1.4;
  text-align: center;
  padding: 6rem;
  border-radius: 1rem;
  backdrop-filter: blur(5px);
  transition: all 0.4s;
}
/* .login_container_card:hover {
  background-color: rgba(49, 62, 118, 0.2);
  border: 1px solid rgba(49, 62, 118, 1);
  backdrop-filter: blur(10px);
} */
.login_container_card_text {
  font-size: 2rem;
  font-weight: 400;
}
.login_container_card_text_big {
  font-size: 2rem;
  font-weight: 300;
}
.login_container_card_text2 {
  font-family: 'Segoe';
  font-size: 2rem;
  font-weight: 600;
  /* color: var(--white2); */
}

/* changes */

.login_container_login {
  grid-column: 6/-1;
  /* grid-row: 2/3; */
  grid-row: 1/-1;
  align-self: center;
  display: flex;
  gap: 1rem;
}
.login_container_loginHeading {
  font-size: 2.5rem;
  font-weight: 300;
  color: var(--black2);
  text-transform: uppercase;
  white-space: nowrap;
  transform: rotate(90deg) translateY(-3.5rem);
  transform-origin: 0% 0%;
}
.login_container_loginBox {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 2px 3rem 5px rgba(49, 62, 118, 0.1);
  backdrop-filter: blur(5px);
  padding: 4rem 5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
.login_container_form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
.login_container_loginBox_logo {
  height: 10rem;
}
.login_container_loginBox_inputIcon {
  height: 2rem;
  width: 2rem;
  fill: var(--grey2);
}
.login_container_loginBox_input {
  box-shadow: 0px 2px 3rem 5px rgba(30, 112, 152, 0.1);
  background-color: var(--white2);
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 1rem;
}
.login_container_loginBox_inputBox {
  background-color: transparent;
  font-family: inherit;
  font-size: 1.5rem;
  outline: none;
  border: none;
  width: 20rem;
  font-weight: 600;
  color: var(--dark-grey2);
}
.login_container_loginBox_inputBoxError {
  text-align: center;
  margin-top: 0.5rem;
  color: var(--red2);
  font-size: 1.2rem;
  font-weight: 500;
  width: 28rem;
}
.login_container_loginBox_button {
  cursor: pointer;
  font-family: inherit;
  text-transform: uppercase;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  background-color: #779559;
  border: 1px solid #779559;
  color: var(--white2);
  border-radius: 3rem;
  font-size: 1.5rem;
  font-weight: 600;
  transition: all 0.4s;
}
.login_container_loginBox_buttonIcon {
  height: 2rem;
  width: 2rem;
  fill: var(--white2);
  transition: all 0.4s;
}
.login_container_loginBox_button:hover {
  background-color: transparent;
  color: #779559;
}
.login_container_loginBox_button:hover .login_container_loginBox_buttonIcon {
  fill: #779559;
}

.login_container_loginBox_register {
  margin-top: 5rem;
  text-align: center;
  color: var(--black2);
  font-size: 1.3rem;
  line-height: 1;
}
.termsandcondition {
  text-align: center;
  color: var(--black2);
  font-size: 1.3rem;
  line-height: 1;
  text-align: center;
}
.agreement {
  text-align: center;
  color: var(--black2);
  font-size: 1.3rem;
  line-height: 1;
  text-align: center;
  margin-top: 1rem;
}
.line {
  color: var(--black2);
  font-size: 1.3rem;
  line-height: 1;
  margin-left: 1rem;
  margin-right: 1rem;
}
.login_container_card_bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
}
.login_container_loginBox_registerBtn {
  color: var(--black2);
  font-size: 2rem;
  font-weight: 200;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  font-family: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
}
.login_container_loginBox_registerBtn span {
  text-transform: capitalize;
}
.otpInput {
  width: 4rem;
  height: 4rem;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  color: var(--primary-color2);
}
.login_container_loginHeading2 {
  display: none;
}
@media (max-width: 575.98px) {
  /* .container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .login_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-position: center;
    background-repeat: no-repeat;
  }
  .login_container_cards {
    display: none;
  }
  .login_container_login {
    grid-column: unset;
    grid-row: unset;
    align-self: unset;
    display: flex;
    flex-direction: column;
  }
  .login_container_loginBox {
    margin-top: 1rem;
    margin: 0 auto;
    padding: 3rem 4rem;
  }
  .login_container_loginHeading {
    display: none;
  }
  .login_container_loginHeading2 {
    display: block;
    font-size: 2.5rem;
    font-weight: 300;
    color: var(--white2);
    text-transform: uppercase;
    white-space: nowrap;
    transform-origin: 0% 0%;
  }
}

@media (min-width: 575px) and (max-width: 767.98px) {
  .login_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  .login_container_cards {
    display: none;
  }
  .login_container_login {
    grid-column: unset;
    grid-row: unset;
    align-self: unset;
    display: flex;
    flex-direction: column;
  }
  .login_container_loginBox {
    margin-top: 1rem;
    margin: 0 auto;
    padding: 3rem 4rem;
  }
  .login_container_loginHeading {
    display: none;
  }
  .login_container_loginHeading2 {
    display: block;
    font-size: 2.5rem;
    font-weight: 300;
    color: var(--white2);
    text-transform: uppercase;
    white-space: nowrap;
    transform-origin: 0% 0%;
  }
}

@media (min-width: 767px) and (max-width: 991.98px) {
  .login_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-position: center;
    background-repeat: no-repeat;
  }
  .login_container_cards {
    display: none;
  }
  .login_container_login {
    grid-column: unset;
    grid-row: unset;
    align-self: unset;
    display: flex;
    flex-direction: column;
    margin-top: -20%;
  }
  .login_container_loginBox {
    margin-top: 1rem;
    margin: 0 auto;
    padding: 6rem 8rem;
  }
  .login_container_loginHeading {
    display: none;
  }
  .login_container_loginHeading2 {
    display: block;
    font-size: 4rem;
    font-weight: 300;
    color: var(--white2);
    text-transform: uppercase;
    white-space: nowrap;
    transform-origin: 0% 0%;
  }
  .login_container_loginBox_logo {
    height: 18rem;
  }
  .login_container_loginBox_input {
    gap: 1rem;
    padding: 1rem 2rem;
    border-radius: 1rem;
  }
  .login_container_loginBox_inputBox {
    font-size: 2rem;
    width: 28rem;
    height: 3.4rem;
  }
  .login_container_loginBox_inputIcon {
    height: 3rem;
    width: 3rem;
  }
  .login_container_loginBox_button {
    gap: 1rem;

    padding: 1.2rem 2.4rem;

    border: 1px solid var(--light-red2);
    border-radius: 1rem;
    font-size: 2.4rem;
    font-weight: 500;
  }
  .login_container_loginBox_buttonIcon {
    height: 3rem;
    width: 3rem;
  }
  .login_container_loginBox_button:hover {
    border: 2px solid var(--light-red2);
  }
  .login_container_loginBox_register {
    margin-top: 5rem;

    font-size: 1.8rem;
    line-height: 1.3;
  }
  .login_container_loginBox_registerBtn {
    font-size: 3.2rem;
    font-weight: 200;
  }
  .login_container_loginBox_inputBoxError {
    font-size: 1.6rem;
    font-weight: 500;
    width: 28rem;
  }
}
