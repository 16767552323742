.skeleton {
	border-radius: 1rem;
	position: relative;
	overflow: hidden;
}
.skeleton::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg, rgba(0, 0, 90, 0), rgba(0, 0, 90, 0.3)), rgba(0, 0, 90, 0);
	animation: progress 1.5s ease-in-out infinite;
}

.skeleton2 {
	border-radius: 1rem;
	position: relative;
	overflow: hidden;
}
.skeleton2::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg, rgba(240, 240, 241, 0.1), rgba(255, 255, 255, 0.9), rgba(231, 231, 234, 0.1));
	animation: progress 1.5s ease-in-out infinite;
}
@keyframes progress {
	0% {
		transform: translate3d(-100%, 0, 0);
	}
	100% {
		transform: translate3d(100%, 0, 0);
	}
}

.flexColumn {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: .5rem;
}

/* Login Skeleton */
.login_container_loginBox_inputBox_skeleton {
	height: 4rem;
	width: 27rem;
	background-color: rgb(226, 226, 226);
}
.login_container_button_skeleton {
	height: 4rem;
	width: 11rem;
	background-color: #ffede6;
}
.login_container_loginBox_register {
	height: 1rem;
	width: 15rem;
	background-color: rgb(226, 226, 226);
}
.login_container_loginBox_registerBtn {
	height: 3rem;
	width: 20rem;
	background-color: rgb(226, 226, 226);
}

/* Mission */
.selectTypes_card_image_skeleton {
	height: 4rem;
	width: 4rem;
	border-radius: 1rem;
	background-color: rgb(19, 82, 141);
}
.selectTypes_card_name_skeleton {
	height: 1.5rem;
	width: 15rem;
	border-radius: 1rem;
	background-color: rgb(19, 82, 141);
}

/* Video */
.selectedPropVideo {
	height: 50rem;
	width: 70%;
	background-color: rgb(19, 82, 141);
}

/* Questions */
.questionTitle {
	height: .8rem;
	width: 35rem;
	border-radius: 1rem;
	background-color: #ffede6;
}
.questionOptions {
	height: .5rem;
	width: 25rem;
	border-radius: 1rem;
	background-color: #ffede6;
	margin-bottom: 1rem;
}
.modal_button_skeleton {
	height: 4rem;
	width: 11rem;
	background-color: #ffede6;
	background-color: var(--lightest-blue2);
	border: 1px solid var(--lightest-blue2);
}

.aboutSkeletonImage {
	height: 10rem;
	width: 10rem;
	background-color: rgb(19, 82, 141);
}
.aboutSkeletonText {
	height: .5rem;
	width: 60vw;
	border-radius: 1rem;
	background-color: rgb(19, 82, 141);
	margin-bottom: 1rem;
}
