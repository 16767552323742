.container {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background-image: linear-gradient(to right top, rgba(159, 203, 230), rgba(255, 255, 255), rgba(159, 203, 230));
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.contentSigUp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}
.header_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo {
  height: 10rem;
}
.main_heading {
  font-family: 'Segoe';
  font-size: 3.5rem;
  font-weight: 600;
  color: var(--dark-blue3);
}
.main_sub_heading {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--dark-blue3);
  font-style: italic;
  text-align: center;
}
.heading {
  font-size: 2rem;
  font-weight: 700;
  color: var(--dark-blue3);
  text-transform: uppercase;
}
.content_box {
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 2px 3rem 5px rgba(30, 112, 152, 0.1);
  border-radius: 1rem;
  padding: 2rem 3rem;
  width: 30vw;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.content_box_wo_bg {
  width: 30vw;

  display: flex;
  gap: 2rem;
}
.plans {
  display: flex;
  gap: 3rem;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.content_box_cards {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.content_box_cards img {
  height: 3.5rem;
}
.headingButtons {
  display: flex;
  gap: 2rem;
  align-items: center;
}

@media (max-width: 1400px) {
  .content_box,
  .content_box_wo_bg {
    width: 40vw;
  }
}
@media (max-width: 1000px) {
  .content_box,
  .content_box_wo_bg {
    width: 60vw;
  }
}
@media (max-width: 867px) {
  .content_box,
  .content_box_wo_bg {
    width: 70vw;
  }


}

@media (max-width: 1020px) {
  .content_box,
  .content_box_wo_bg {
    width: 70vw;
  }

  .contentHeader{
    padding-left: 2rem;
  }
}
@media (max-width: 500px) {
  .content_box,
  .content_box_wo_bg {
    width: 90vw;
  }
}
@media (max-width: 280px) {
  .content_box {
    padding: 2rem 1rem;
  }
}
