.progress_bar_container{
    height: 1rem;
    width: 100%;
    border-radius: 2rem;
    overflow: hidden;
    transition: all 2rem ease;
    margin: 1rem 0 0 0 ;
}

.progress_bar{
    background-color: var(--primary);
    height: 100%;
    border-radius: 2rem;
    width: 0;
    transition: all 2rem ease;
}   