.drag_ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.drag_li {
  font-size: 1.7rem;
  padding: 0.8rem 0;
  border-bottom: 1px solid var(--light-grey2);

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drag_li:hover {
  background: darken(#eee, 50%);
  cursor: -webkit-grab;
  cursor: grab;
}
.drag_li_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--light-blue2);
}
.drag_placeholder {
  background: var(--lightest-blue2);
  padding: 0.5rem;
}
.drag_placeholder:before {
  content: 'Drop here';
  font-family: inherit;
  font-size: 1.4rem;
  color: var(--white2);
}
