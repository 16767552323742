.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.paginationContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
}

.paginationBtn {
  font-size: 1.8rem;
  padding: 1rem 2rem;
  background-color: var(--primary-light);
  color: #ffffff;
  border-radius: 0.5rem;
}

.paginationBtn:hover {
  background-color: var(--primary);
  cursor: pointer;
}

.true {
  pointer-events: none;
  background-color: #9e9e9e;
}
.false {
  pointer-events: all;
  background-color: #404f90;
}
