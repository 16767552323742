.videoContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  position: relative;
}
.videoContainer video {
  height: 100%;
  width: 60%;
  min-width: 90rem;
  border-radius: 2rem;
  cursor: pointer;
}
.playBtnContainer_visible {
  opacity: 1 !important;
}
.playBtnContainer {
  /* position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 100.1%;
	width: 65%;
	min-width: 90rem;
	backdrop-filter: blur(4px);
	background-color: rgba(48, 60, 109, .2);
	border-radius: 2rem;
	z-index: 1000;
	opacity: 0; */
  opacity: 1;
  margin-bottom: 1rem;
  transition: all 0.4s;
  display: flex;
  gap: 1rem;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
}
.playBtnContainerText {
  width: 10rem;
  transition: width 0.5s ease;
}
.play-btn {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: relative;
  margin: auto;
  transform: rotate(0deg);
  transition: -webkit-clip-path 0.3s ease-in 0.1s,
    shape-inside 0.3s ease-in 0.1s,
    transform 0.8s cubic-bezier(0.85, -0.25, 0.25, 1.425);
}
.play-btn.paused,
.play-btn.playing {
  transform: rotate(-90deg) scale(0.8);
}

.play-btn:before,
.play-btn:after {
  content: '';
  position: absolute;
  background: white;
  /* background-color: var(--white); 2*/
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: inherit;
}
.play-btn:before {
  clip-path: polygon(10% 0, 90% 51%, 90% 51%, 10% 51%);
  -webkit-clip-path: polygon(10% 0, 90% 51%, 90% 51%, 10% 51%);
  shape-inside: polygon(0 0, 100% 51%, 100% 51%, 0 51%);
}
.play-btn:after {
  clip-path: polygon(10% 49.5%, 80% 49.5%, 90% 49.5%, 10% 100%);
  -webkit-clip-path: polygon(10% 49.5%, 80% 49.5%, 90% 49.5%, 10% 100%);
  shape-inside: polygon(10% 49.5%, 80% 49.5%, 90% 49.5%, 10% 100%);
}
.play-btn.paused:before,
.play-btn.playing:before {
  clip-path: polygon(0 10%, 100% 10%, 100% 40%, 0 40%);
  -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 40%, 0 40%);
  shape-inside: polygon(0 10%, 100% 10%, 100% 40%, 0 40%);
}
.play-btn.paused:after,
.play-btn.playing::after {
  clip-path: polygon(0 60%, 100% 60%, 100% 90%, 0 90%);
  -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 90%, 0 90%);
  shape-inside: polygon(0 60%, 100% 60%, 100% 90%, 0 90%);
}

.seekBar {
  position: absolute;
  height: 0.3rem;
  width: 60%;
  min-width: 90rem;
  background-color: var(--grey2);
  bottom: -1rem;
  border-radius: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  font-size: 1.2rem;
}
.currentPosition {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.3rem;
  width: 0%;
  background-color: var(--light-blue2);
  transition: all 0.1s;
}
