.client_card_container {
  background-color: #f5f4f4;
  box-shadow: 0 0.3rem 1rem 0.3rem rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  padding: 3rem;
  transition: all 0.3s ease;
}
.client_card_container:hover {
  transform: scale(1.03);
  /* transform: translate(.2rem, -.3rem); */
}

.client_card_view_container {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  border-bottom: 1px solid var(--light-grey);
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
}

.client_card_image {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
}

.mission_card_message {
  margin-top: 1rem;
  font-size: 1.4rem;
  color: var(--secondary);
}
.mission_card_message2 {
  margin-top: 1rem;
  font-size: 1.4rem;
  color: var(--red);
}
/* .client_card_detail_container {
	display: flex;
	flex-direction: column;
	gap: .5rem;
} */
.client_card_title {
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: capitalize;
}
.client_card_count {
  font-size: 1.2rem;
  font-weight: 600;
}
.client_card_view_space_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.client_card_view_space_container select {
  font-family: inherit;
  outline: none;
  border: none;
  border-bottom: 1px solid var(--primary);
  background-color: transparent;
}
.client_card_view_space {
  text-decoration: none;
  font-family: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--secondary);
  font-style: italic;
  text-transform: capitalize;
}
.client_card_action_container {
  display: flex;
  justify-content: space-between;
}
.client_card_action_container_left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.client_card_edit_image {
  height: 2.4rem;
  width: 2.4rem;
  cursor: pointer;
}

.client_card_edit_image2 {
  height: 2.2rem;
  width: 2.2rem;
  cursor: pointer;
}

.client_card_edit_image_small {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
}
.client_card_delete_image {
  height: 2.6rem;
  width: 2.6rem;
  cursor: pointer;
}
.client_card_pdf_image_container {
  width: 100%;
  height: 14rem;

  display: flex;
  align-items: center;
  justify-content: center;
}
.client_card_pdf_image {
  width: 50%;
  height: 70%;
}

.coupon_card_container {
  display: flex;
  gap: 2rem;
}

.coupon_card_text {
  font-size: 2rem;
  color: var(--primary);
  font-weight: bold;
}
.coupon_card_text2 {
  font-size: 2.4rem;
  color: var(--green);
  font-weight: bold;
  border-left: 1px solid var(--light-grey);
  padding-left: 2rem;
}
.coupon_card_duration {
  margin-top: 0.5rem;
  font-size: 1.6rem;
  color: var(--primary);
  font-weight: bold;
}
.coupon_card_active {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.coupon_card_options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
