.input_container {
	width: 100%;
	margin-top: 2rem;
}

.text_input_container {
	display: flex;
	align-items: center;
	border-bottom-width: .2rem;
	border-bottom-color: var(--primary);
	border-bottom-style: solid;
}

.text_input {
	font-size: 1.3rem;
	border: none;
	outline: none;
	padding: 1rem 1.5rem;
	background-color: transparent;
	flex: 1;
}

.text_input_image_container {
	height: 3.5rem;
	width: 3.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary);
	border-radius: 5rem;
	overflow: hidden;
}

.text_input_image {
	height: auto;
	width: 4rem;
	padding: 1rem;
	color: var(--white);
}

.text_input_password {
	height: 4rem;
	width: 4rem;
	padding: .6rem;
	border-radius: 10rem;
	cursor: pointer;
}

.input_error_text {
	color: var(--red);
	font-size: 1.3rem;
	margin: .5rem 0;
}

@media (max-width: 769px) {
	.text_input_image_container {
		height: 3rem;
		width: 3rem;
		border-radius: 5rem;
	}

	.text_input_image {
		width: 3rem;
		padding: 0.7rem;
	}

	.text_input {
		font-size: 1.3rem;
	}

	.input_error_text {
		font-size: 1.2rem;
	}
}
