.container {
  width: 100%;
}
.compulsory {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.compulsoryTitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--primary);
}
.switch {
  margin-left: 5rem;
}
/* .textArea {
} */
.input {
  width: 49%;
}
.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
