.pointsEarned{
    font-size: 2rem;
    font-weight: 600;
}

.big_heads_container{
    height: 7rem;
    width: 7rem;
    position: absolute;
    top: 0;
    left: 1rem;
    right: 0;
}

.big_heades_main_card_container{
    background-color: #f5f4f4;
	box-shadow: 0 .3rem 1rem .3rem rgba(0, 0, 0, 0.1);
	border-radius: 1.5rem;
	padding: 3rem;
	transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.big_heades_card_container {
    display: flex;
    width: 42rem;
}

.big_heades_main_card_container:hover {
	transform: scale(1.03);
}

.big_heades_card_view_container {
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--light-grey);
	padding-bottom: 1.5rem;
	margin-bottom: 1rem;
    width: 100%;
}

.big_heades_card_view{
    flex:1;
}

.big_heades_card_title{
    font-size: 2rem;
    font-weight: 500;
    text-transform: capitalize;
}

.rank_container{
    margin-bottom: -5rem;
    margin-top: 1rem;
    border-radius: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    width: 15rem;
}

.rank_container p{
    font-size: 1.8rem;
    text-transform: capitalize;
    color: var(--white);
}