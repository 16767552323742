.inputSubContainerCheckbox {
  margin-top: 2rem;
}

.container {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 8rem;
  overflow: auto;
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading {
  font-family: 'Sarina';
  font-style: normal;
  font-weight: 400;
  font-size: 3.6rem;
  line-height: 6rem;
  color: #ffffff;
}
.logouticon {
  width: 3.5rem;
  height: 3.5rem;
  fill: #ffffff;
  cursor: pointer;
}
.subheading {
  margin-top: 1.5rem;
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 2.2rem;
  color: #ffffff;
  margin-bottom: 10rem;
}
.paragraph {
  margin-top: 1.5rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8;
  width: 78.8rem;
  color: #ffffff;
}
.desc {
  margin-top: 4rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 1.75rem;
  line-height: 1.9rem;
  color: #ffffff;
}
.cardContainer {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-top: 2rem;
}
.card {
  width: 34rem;
  height: 12.5rem;
  background: rgba(49, 62, 118, 0.51);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  will-change: contents;
}
.comingsoonCard {
  pointer-events: none;
  position: relative;
  background-color: rgba(189, 187, 187, 0.005);
  border-radius: 1rem;
}
.disable {
  opacity: 0.1;
}
.disable2 {
  pointer-events: none;
}
.comingsoon {
  position: absolute;
  /* color: #e56e00; */
  color: #fff;
  z-index: 100;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  line-height: 2.1rem;
  top: 40%;
  left: 25%;
  text-transform: uppercase;
}
.card:hover {
  border: 1px solid #ffffff;
}
.active {
  border: 1px solid #ffffff;
}
.p1 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8em;
  line-height: 2.3rem;
  text-align: center;
  color: #ffffff;
  will-change: contents;
}
.p2 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3rem;
  will-change: contents;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 1rem;
}
.p3 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  will-change: contents;
  color: #ffffff;
  text-transform: uppercase;
}

.bottomContainer {
  margin-top: 4rem;
  display: none;
}
.bottom_title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.4rem;
  color: #ffffff;
  margin-bottom: 2rem;
  text-transform: uppercase;
}
.inputContainer {
  display: flex;
  gap: 5rem;
  margin-top: 1rem;
}
.label {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 4rem;
  color: #ffffff;
  margin-left: 0.4rem;
  text-transform: capitalize;
}
.input {
  margin-top: 0.5rem;
  display: flex;
  width: 34rem;
  height: 4.2rem;
  background: rgba(49, 62, 118, 0.51);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1rem);
  border-radius: 1rem;
  padding: 3rem 1rem;
  align-items: center;
}
.icon {
  width: 2rem;
  height: 2rem;
  fill: var(--light-brown);
}
.inputText {
  font-size: 1.8rem;
  appearance: none;
  margin-left: 12px;
  border: none;
  resize: none;
  outline: none;
  background-color: transparent;
  color: #ffffff;
}
.inputText::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

.buttonContainer {
  margin-top: 3rem;
}
.button {
  width: 14rem;
  height: 5rem;
  background: rgba(255, 77, 0, 0.47);
  border-radius: 0.5rem;
  appearance: none;
  outline: none;
  border: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  cursor: pointer;
}
.button_unselected {
  width: 14rem;
  height: 5rem;
  background: rgba(194, 192, 191, 0.47);
  border-radius: 0.5rem;
  appearance: none;
  outline: none;
  border: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  cursor: pointer;
}
.buttonText {
  font-size: 2.4rem;
}
.line {
  width: 3rem;
  height: 0.1rem;
  background: #ffffff;
}
.login_container_loginBox_inputBoxError {
  text-align: center;
  margin-top: 0.5rem;
  color: var(--red2);
  font-size: 2rem;
  font-weight: 500;
  width: 28rem;
}

@media (max-width: 575.98px) {
  .container {
    padding: 1rem 2rem;
    background-position: center;
    background-repeat: no-repeat;
  }
  .heading {
    margin: 2rem 0rem;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .logouticon {
    width: 2.4rem;
    height: 2.4rem;
  }
  .subheading {
    margin-top: 1.5rem;

    font-size: 1.3rem;
    line-height: 1.5rem;
    color: #ffffff;
  }
  .desc {
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 1.4rem;
  }
  .card {
    width: 17rem;
    height: 11.5rem;
    backdrop-filter: blur(1rem);
    border-radius: 1rem;
  }
  .p1 {
    font-size: 1.2em;
    line-height: 1.2rem;
  }
  .p2 {
    font-size: 1.4rem;
    line-height: 1.4rem;
    margin-top: 1rem;
    text-align: center;
  }
  .bottom_title {
    font-size: 1.4rem;
    line-height: 1.2rem;
    text-align: center;
  }
  .buttonText {
    font-size: 1.4rem;
  }
  .label {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  .inputContainer {
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input {
    margin-top: 0.5rem;
    width: 26rem;
    height: 4rem;
    padding: 0.5rem 1.5rem;
    align-items: center;
  }
  .icon {
    width: 1.4rem;
    height: 1.4rem;
  }
  .button {
    width: 10rem;
    height: 3.8rem;
    gap: 0.5rem;
  }
}

@media (min-width: 575px) and (max-width: 767.98px) {
  .container {
    padding: 3rem 6rem;
    background-position: center;
    background-repeat: no-repeat;
  }
  .heading {
    font-weight: 400;
    font-size: 3rem;
    line-height: 4rem;
  }
  .logouticon {
    width: 3rem;
    height: 3rem;
  }
  .subheading {
    margin-top: 1.5rem;
    font-size: 1.8rem;
    line-height: 2rem;
    color: #ffffff;
  }
  .desc {
    margin-top: 2rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .card {
    width: 24rem;
    height: 12rem;
    backdrop-filter: blur(1rem);
    border-radius: 1rem;
  }
  .p1 {
    font-size: 1.4em;
    line-height: 1.2rem;
  }

  .bottom_title {
    font-size: 1.8rem;
    line-height: 1.8rem;
    text-align: center;
  }
  .label {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  .inputContainer {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    flex-direction: column;
    align-items: center;
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input {
    margin-top: 1rem;
    width: 36rem;
    height: 5rem;
    padding: 0.5rem 1.5rem;
    align-items: center;
  }
  .icon {
    width: 2.2rem;
    height: 2.2rem;
  }
  .button {
    width: 13rem;
    height: 5rem;
    gap: 0.8rem;
    font-size: 1.7rem;
  }
  .p2 {
    text-align: center;
  }

  .inputText {
    margin-left: 1rem;
    font-size: 1.6rem;
  }
}

@media (min-width: 767px) and (max-width: 991.98px) {
  .container {
    padding: 3rem 6rem;
    background-position: center;
    background-repeat: no-repeat;
  }
  .heading {
    font-weight: 400;
    font-size: 3rem;
    line-height: 4rem;
  }
  .logouticon {
    width: 3rem;
    height: 3rem;
  }
  .subheading {
    margin-top: 1.5rem;
    font-size: 1.8rem;
    line-height: 2rem;
    color: #ffffff;
  }
  .desc {
    margin-top: 2rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .card {
    width: 24rem;
    height: 12rem;
    backdrop-filter: blur(1rem);
    border-radius: 1rem;
  }
  .p1 {
    font-size: 1.4em;
    line-height: 1.2rem;
  }

  .bottom_title {
    font-size: 1.8rem;
    line-height: 1.8rem;
    text-align: center;
  }
  .label {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  .inputContainer {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    flex-direction: column;
    align-items: center;
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input {
    margin-top: 1rem;
    width: 36rem;
    height: 5rem;
    padding: 0.5rem 1.5rem;
    align-items: center;
  }
  .icon {
    width: 2.2rem;
    height: 2.2rem;
  }
  .button {
    width: 13rem;
    height: 5rem;
    gap: 0.8rem;
    font-size: 1.7rem;
  }
  .p2 {
    text-align: center;
  }

  .inputText {
    margin-left: 1rem;
    font-size: 1.6rem;
  }
 
  
}
