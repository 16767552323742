.send_notification {
  background-color: var(--gray);
  padding: 2rem 3rem;
  border-radius: 2rem;
  width: 30vw;
}
.send_notification_heading {
  font-size: 1.7rem;
  font-weight: 600;
}
.send_notification_btn {
  font-family: inherit;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--white);
  font-size: 1.5rem;
  margin-top: 2rem;
  padding: 1rem 2rem;
  cursor: pointer;

  display: flex;
  gap: 0.5rem;
  align-items: center;
  transition: all 0.4s;
}
.send_notification_btn:hover {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
}

.send_notification_btn3 {
  font-family: inherit;
  border: 1px solid var(--primary);
  background: rgba(182, 215, 255, 0.35);
  color: var(--white);
  font-size: 1.5rem;
  margin-top: 2rem;
  padding: 1rem 2rem;
  cursor: pointer;

  display: flex;
  gap: 0.5rem;
  align-items: center;
  transition: all 0.4s;
}
.send_notification_btn3:hover {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
}

.send_notification_btn_icon {
  height: 2rem;
}

.send_notification_btn2 {
  font-family: inherit;
  border: none;
  background-color: transparent;
  color: var(--red);
  font-size: 1.5rem;
  margin-top: 2rem;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  transition: all 0.4s;
}
/* .send_notification_btn2:hover {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
} */

.subscription_btn_container {
  display: flex;
  gap: 4rem;
  align-items: center;
}
.previous_notifications {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.previous_notifications_search {
  margin-top: 2rem;
  background-color: var(--gray);
  border-radius: 2rem;
  padding: 2rem;
  width: 20vw;

  display: flex;
  gap: 1rem;
  align-items: center;
}
.previous_notifications_search_input {
  font-family: inherit;
  border: none;
  background-color: transparent;
  width: 100%;
  outline: none;
}
.previous_notifications_search_label {
  display: flex;
  align-items: center;
  justify-content: center;
}
.previous_notifications_search_icon {
  height: 2rem;
}
.previous_notifications_cards {
  margin-top: 1rem;
  height: 50vh;
  overflow: auto;
}
.previous_notifications_card {
  margin: 1rem 0;
  margin-right: 2rem;
  padding: 2rem;
  border-radius: 2rem;
  background-color: var(--gray);
}
.previous_notifications_heading {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--primary);
}
.previous_notifications_content {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--light-grey);
  font-size: 1.2rem;
  width: 40vw;
}
.previous_notifications_date {
  color: var(--secondary);
  font-weight: 500;
  font-size: 1.1rem;
  text-align: right;
}
