.container {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 8rem;
}
.subContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.header {
  font-family: 'Sarina';
  font-style: normal;
  font-weight: 400;
  font-size: 5rem;
  line-height: 8rem;
  color: #ffffff;
}

.image {
  margin-top: 1.5rem;
}
.img {
  width: 22rem;
  height: 22rem;
}
.text1 {
  margin-top: 2.5rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 3.8rem;
  line-height: 5rem;
  color: #ffffff;
}
.text2 {
  margin-top: 1.5rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 4rem;
  color: #ffffff;
}
.button {
  margin-top: 2.5rem;
  width: 24rem;
  height: 5.2rem;
  background: #e56e00;
  border-radius: 1rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
}
.logoContainer {
  position: absolute;
  left: 0;
  top: -1rem;
}
.img2 {
  width: 18rem;
  height: 21rem;
}
@media (max-width: 575.98px) {
  .container {
    background-position: center;
    background-repeat: no-repeat;
    padding: 4rem 8rem;
  }
  .header {
    font-size: 3rem;
    line-height: 5rem;
    text-align: center;
  }
  .logoContainer {
    position: relative;
  }
  .text2 {
    margin-top: 1.5rem;
    font-size: 2rem;
    line-height: 3.6rem;
    text-align: center;
  }
  .button {
    margin-top: 2.5rem;
    width: 18rem;
    height: 4.6rem;

    border-radius: 1rem;

    font-weight: 600;
    font-size: 2.2rem;
    line-height: 3rem;
  }
}

@media (min-width: 575px) and (max-width: 767.98px) {
  .container {
    background-position: center;
    background-repeat: no-repeat;
    padding: 4rem 8rem;
  }
  .header {
    font-size: 3rem;
    line-height: 5rem;
    text-align: center;
  }
  .logoContainer {
    position: relative;
  }
  .text2 {
    margin-top: 1.5rem;
    font-size: 2rem;
    line-height: 3.6rem;
    text-align: center;
  }
  .button {
    margin-top: 2.5rem;
    width: 18rem;
    height: 4.6rem;

    border-radius: 1rem;

    font-weight: 600;
    font-size: 2.2rem;
    line-height: 3rem;
  }
}

@media (min-width: 767px) and (max-width: 991.98px) {
  .container {
    background-position: center;
    background-repeat: no-repeat;
    padding: 4rem 8rem;
  }
  .header {
    font-size: 4rem;
    line-height: 5rem;
    text-align: center;
  }
  .logoContainer {
    position: relative;
  }
  .text2 {
    margin-top: 1.5rem;
    font-size: 2.2rem;
    line-height: 3.6rem;
    text-align: center;
  }
  .button {
    margin-top: 2.5rem;
    width: 22rem;
    height: 5.2rem;
    border-radius: 1rem;
    font-weight: 600;
    font-size: 2.6rem;
    line-height: 3rem;
  }
}
