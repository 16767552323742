.howto_editlogo {
  height: 2.3rem;
  width: 2.3rem;
  cursor: pointer;
}

.howto_main_content {
  margin-top: 5rem;
  max-width: max-content;
}

.search_container {
  background-color: var(--gray);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  margin-left: 2rem;
  transition: all 0.3s ease;
}
.search_container2 {
  background-color: var(--gray);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  width: 30rem;
  align-items: center;
  margin-left: 2rem;
  transition: all 0.3s ease;
}
.background_container_header_input {
  outline: none;
  border: none;
  background-color: var(--transparent);
  transition: all 0.3s ease;
  font-size: 1.3rem;
  font-weight: 500;
}

/* .background_container_header_input:hover{
    background-color: var(--secondary-light)
} */

.howto_search_logo {
  height: 1.7rem;
  width: 1.7rem;
  cursor: pointer;
}

.howto_edit_modal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.howto_edit_modal_left {
  grid-column: 1/3;
}

.howto_edit_modal_right {
  grid-column: 3/-1;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}

.color_dropdown {
  background-color: var(--light-white);
  border-radius: 0.5rem;
  margin: 1rem 0;
  padding: 0.5rem 0rem;
  box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-height: 16vh;
  overflow-y: auto;
}

.color_dropdown_item {
  cursor: pointer;
  margin: 1rem;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
}

.circle_dropdown_item {
  height: 3rem;
  width: 3rem;
  border-radius: 3rem;
  margin-right: 1.5rem;
}

.version_toggle_conatainer {
  margin-left: 2rem;
}

@media (max-width: 500px) {
  .howto_editlogo {
    height: 2rem;
    width: 2rem;
  }
}
