.container {
  height: 60vh;
  width: 60%;
  min-width: 90rem;
  border-radius: 2rem;
  cursor: pointer;
}
.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
}

.modalAnswerNext_next_btn3 {
  align-self: flex-end;
  font-family: inherit;
  background-color: var(--white2);
  border: 1px solid var(--lightest-blue2);
  border-radius: 1rem;
  padding: 1rem 2rem;
  color: var(--grey2);
  font-weight: 600;
  font-size: 1.4rem;
  opacity: 1;
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s;
}
.modalAnswerNext_next_btn3 span {
  height: 1px;
  width: 3rem;
  background-color: var(--grey2);
}
