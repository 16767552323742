.main_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_left_content {
  display: flex;
  align-items: center;
}

.main_header_menu_icon {
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  margin-right: 1rem;
  color: var(--primary);
}

.main_header_heading {
  color: var(--primary);
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: 600;
}

.change_password_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.change_password {
  margin-right: 4rem;
  display: flex;

  align-items: center;

  width: 4rem;
  height: 4rem;
  box-shadow: 0px 4px 16px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  color: var(--primary);
  border-radius: 2rem;
}
.change_password img {
  width: 1.8rem;
  height: 1.8rem;
  text-align: center;
  margin-left: 1.2rem;
}

.change_password img:hover {
  cursor: pointer;
}

.dropdown_container {
  position: relative;
  width: max-content;
  z-index: 101;
}

.dropdown_container .dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
}

.dropdown_container .dropdown .role_title {
  font-size: 1.8rem;
  margin: 0 1rem;
  color: var(--secondary);
  text-transform: capitalize;
}

.role_image {
  height: 1.8rem;
  width: 1.8rem;
}

.list_container {
  background-color: var(--light-white);
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem 0rem;
  position: absolute;
  box-shadow: 15px 14px 16px 4px rgba(0, 0, 0, 0.09);
  width: max-content;
  right: 0;
  max-height: 20rem;
  overflow-y: auto;
  margin-right: 1rem;
}

.dropdown_item {
  cursor: pointer;
  margin: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.5s ease;
}

.selected_dropdown_item {
  cursor: pointer;
  margin: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.5s ease;
  background-color: var(--dark-white);
  color: var(--secondary);
  font-size: 1.5rem;
  text-transform: capitalize;
}

.dropdown_item:hover {
  background-color: var(--dark-white);
  color: var(--secondary);
}

.dropdown_item .dropdown_item_userName {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.arrow_up_down {
  height: 1.8rem;
  width: 1.8rem;
}

/* 769 */
@media (max-width: 860px) {
  .main_header_heading {
    font-size: 1.5rem;
  }

  .dropdown_container .dropdown .role_title {
    font-size: 1.3rem;
    margin: 0 0.5rem;
  }

  .dropdown_item .dropdown_item_userName {
    font-size: 1.3rem;
  }

  .selected_dropdown_item {
    font-size: 1.3rem;
  }
}

@media (max-width: 500px) {
  .main_header_heading {
    font-size: 1.3rem;
  }

  .main_header_menu_icon {
    height: 2rem;
    width: 2rem;
  }

  .role_image {
    height: 1.3rem;
    width: 1.3rem;
  }

  .arrow_up_down {
    height: 1.3rem;
    width: 1.3rem;
  }

  .dropdown_container .dropdown .role_title {
    font-size: 1.1rem;
  }

  .dropdown_item .dropdown_item_userName {
    font-size: 1.1rem;
  }

  .selected_dropdown_item {
    font-size: 1.1rem;
  }
}
