.client__container {
  margin-top: 3rem;
  padding-top: 2rem;
  padding-bottom: 5rem;
  height: 75vh;
  overflow-y: auto;
}
.missions_main_content {
  /* margin: 5rem 0 3rem 0;
    display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 3rem;
	grid-template-rows: repeat(3, 1fr);
	padding: 0 3rem 2rem 2rem; */

  display: flex;
  flex-wrap: wrap;
  row-gap: 3rem;
  column-gap: 3rem;
  /* align-items: flex-start; */
  padding-left: 1rem;
}
.client__container_message {
  color: var(--primary);
  font-size: 1.6rem;
  font-weight: 500;
  margin-left: 2rem;
  margin-bottom: 2rem;
}
.active_status_text {
  margin: 2rem 0 1rem 0;
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 500;
}

.mission_dropdown_container {
  position: relative;
  z-index: 101;
}

.mission_list_container {
  background-color: var(--white);
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  position: absolute;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.1);
  left: 4rem;
  right: 0;
  max-height: 20rem;
  overflow-y: auto;
}

.mission_list_item {
  background-color: var(--dark-white);
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.mission_list_item:hover {
  background-color: var(--lightest-grey);
}

.mission_list_item_title {
  font-size: 1.5rem;
  font-weight: 500;
}
option::after {
  content: 'asd';
  height: 2rem;
  width: 2rem;
  background-color: black;
}
.filter_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10rem;
  margin-bottom: 2rem;
}
.filter_section {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filter_label {
  margin-left: 1rem;
  font-size: 1.4rem;
  margin-right: 2rem;
}
