 .big_heads_category_container {
     height: 7rem;
     width: 7rem;
     position: absolute;
     top: 0;
     left: 1rem;
     right: 0;
 }

 .big_heades_main_category_card_container {
     background-color: #f5f4f4;
     box-shadow: 0 .3rem 1rem .3rem rgba(0, 0, 0, 0.1);
     border-radius: 1.5rem;
     padding: 2rem;
     transition: all .3s ease;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     width: 50rem;
 }

 .big_heades_category_card_container {
     display: flex; 
     border-bottom: 1px solid #0f407a;
     width: 100%;
 }

 .big_heades_main_category_card_container:hover {
     transform: scale(1.03);
 }

 .big_heades_category_card_view_container {
     display: flex;
     align-items: center;
     padding-bottom: 1.5rem;
     margin-bottom: 1rem;
     width: 100%;
 }

 .big_heades_category_card_view {
     flex: 1;
 }

 .big_heades_category_card_title {
     font-size: 2rem;
     font-weight: 500;
     text-transform: capitalize;
 }


 /* ===============================================================================
============================= Top Content =======================================
=============================================================================== */

 .topData {
     display: flex;
     grid-gap: 1rem;
     gap: 1rem;
     align-items: center;
     background: #2196f34d;
     border-radius: 1rem;
     margin: 0.5rem;
     width: 12rem;
 }

 .topData__name {
     width: 8rem;
     font-size:  0.9rem;
     font-weight: 600;
     margin: 0;
 }

 .topData__bar {
     height: 2rem;
     background-color: rgb(11, 211, 24);
     max-width: 80%;
     min-width: 5%;
     padding-left: 1rem;
     color: rgb(0, 0, 0);
     font-size: 0.9rem;
     border-radius: 1rem;
     font-weight: 600;
     display: flex;
     align-items: center;
 }

 .topData__view {
     color: rgb(0, 0, 0);
     font-size:  0.9rem;
     border-radius: 50%;
     font-weight: 600;
 }

 .topData__text{
    color: rgb(0, 0, 0);
    font-size: 0.9rem;
    border-radius: 50%;
    font-weight: 600; 
    position: absolute;  
    margin-top: 0.2rem;
 }


 .topData__col{ 
    align-items: center;
    justify-content: center; 
    display: flex;
    flex-wrap: wrap; 
    position: relative;
    /* row-gap: 1rem;  */
 }