.container {
  display: flex;
}
.subContainer1 {
  width: 60%;

  /* background-color: red; */
}

.subContainer2 {
  width: 40%;
  margin-left: 2rem;
  padding: 2rem;
  border-left: 2px solid white;
  /* height: 72vh; */
  /* background-color: red; */
  flex-shrink: 0;
}
.subContainerTop {
  display: flex;
  gap: 1.5rem;
}
.box {
  position: relative;
  width: 19rem;
  background: #495eb4;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem 1rem 0.5rem;
}
.trophy {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.ribbonContainer {
  position: relative;
  margin-top: -0.5rem;
}
.ribbon {
  width: 3.5rem;
  height: 5.2rem;
}
.rankNumber {
  position: absolute;
  font-family: 'Sarina';
  font-style: normal;
  font-size: 1rem;
  right: 1.1rem;
  top: 1rem;
}
.hash {
  font-family: 'Raleway';
}
.trophyicon {
  width: 1.4rem;
  height: 1.6rem;
  margin-left: 0.5rem;
}
.bigheadsContainer {
  width: 6.5rem;
  height: 6.5rem;
}
.boxName {
  margin-top: 0.5rem;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.5rem;
  text-align: center;
  color: #ffffff;
}
.boxDetails {
  margin-top: 0.5rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  color: #ffffff;
}
.progressBar {
  margin-top: 1rem;
  width: 100%;
  height: 0.5rem;
  background-color: var(--light-blue2);
  box-shadow: 0px 2px 1rem 5px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  transition: all 0.5s ease;
}

.progress {
  width: 50%;
  height: 100%;
  background-color: var(--dark-blue2);
  border-radius: 1rem;
}
.subContainerBottom {
  width: 100%;
  height: 100%;
}
.tilesContainer {
  margin-top: 5rem;
}
.tiles {
  position: relative;
  margin-top: 1.5rem;
  width: 100%;
  height: 3.7rem;
  background: #495eb4;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.bigheadsContainer2 {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
  margin-bottom: 0.3rem;
}
.tileName {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.tileProgress {
  margin-top: 0;
  width: 15rem;
}

.progressTileText {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.coinText {
  font-size: 2rem;
  color: var(--secondary);
}
.progressTileContainer {
  width: 10rem;
  position: absolute;
  transform: translate(400%);
  display: flex;
  align-items: center;
  gap: 1rem;
}
.subContainer2Heading {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #ffffff;
}
.subContainer2TextContainer {
  margin-top: 4rem;
}
.subContainer2Title {
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.5rem;
}
.subContainer2Desc {
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.boxSkeleton {
  position: relative;
  width: 19rem;
  height: 11.8rem;
  background: #495eb4;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  padding: 0 0.5rem 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.circleSekeleton {
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  background-color: var(--primary);
}
.lineSekeleton {
  width: 90%;
  height: 1rem;
  border-radius: 5px;
  background-color: var(--primary);
  margin-top: 1rem;
}
.smallCircleSekeletonContainer {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.2rem;
}
.smallCircleSekeleton {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 1.8rem;
  background-color: var(--primary);
}
.progressSekeleton {
  margin: 0 auto;
  width: 19rem;
  height: 0.8rem;
  border-radius: 1rem;
  background: #495eb4;
  margin-top: 1rem;
}
.tilesSkeleton {
  width: 100%;
  height: 4rem;
  background: #495eb4;
  border-radius: 1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mediumCircleSekeleton {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 2.6rem;
  background-color: var(--primary);
  margin-left: 1rem;
}
.rightHeaderSkeleton {
  width: 90%;
  margin: 0 auto;
  height: 3rem;
  background: #495eb4;
  border-radius: 1rem;
}
.rightTitleSkeleton {
  width: 90%;
  margin: 0 auto;
  height: 4rem;
  background: #495eb4;
  border-radius: 1rem;
  margin-top: 2rem;
}
.rightDescSkeleton {
  width: 90%;
  margin: 0 auto;
  height: 10rem;
  background: #495eb4;
  border-radius: 1rem;
  margin-top: 2rem;
}
.messageContainer {
  width: 100%;
  height: 60rem;
  font-size: 2.4rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.timer {
  font-size: 2rem;
  text-align: end;
  margin-top: -1rem;
  margin-bottom: 2rem;
}
.rankText {
  font-size: 1rem;
  margin-left: 1rem;
}
.rankBigtext {
  font-size: 1.6rem;
  color: 'white';
}
.blur {
  color: transparent;
  text-shadow: 0 0 8px #fff;
  pointer-events: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.coin {
  width: 2.5rem;
  height: 2.5rem;
}
