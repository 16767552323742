/* ############# Global styles started ############# */

.container {
  transition: all 0.5s ease;
  display: grid;
  grid-template-columns: minmax(5rem, 2rem) repeat(6, 1fr);
  gap: 2rem;
  height: 100vh;
  background-color: var(--primary);
}

.container_active {
  grid-template-columns: minmax(5rem, 20rem) repeat(6, 1fr);
}

.main_container {
  grid-column: 2/-1;
  border-radius: 2rem 0 0 2rem;
  padding: 5rem;
  transition: all 0.5s ease;
  position: absolute;
  left: 25rem;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white);
}

.main_container_active {
  transition: all 0.5s ease;
  left: 8rem;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.nodata_message {
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: capitalize;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-left {
  margin-left: 1rem;
}

/* ############# Global styles End ############# */

/* ------------------------------------------------------------------------------------------------------------------------- */

/* ############# Custom Toast styles Started ############# */

.Toastify__progress-bar {
  background-color: var(--white);
}

.Toastify__close-button > svg {
  color: var(--white);
}

.Toastify__toast-body {
  font-size: 1.8rem;
}

.Toastify__toast--success {
  background-color: var(--primary-light) !important;
}

.Toastify__toast--error {
  background-color: var(--red) !important;
}

.go4109123758 {
  font-size: 1.5rem;
}

/************************** Custom Scroll Bar  *************************/
::-webkit-scrollbar {
  height: 1rem;
  width: 0.5rem;
  left: -100px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  background-color: var(--dark-white);
  border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-light);
}

/**************************************** Common Header ****************************************/

.form_header_text {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--primary);
  text-transform: capitalize;
}
.form_header_delete_text {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--secondary);
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.form_header_delete_text span {
  font-weight: 600;
  color: var(--red);
}
.form_header_delete_ref {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.form_header_delete_note {
  width: 50rem;
}
.form_header_delete_ref_text {
  font-size: 1.2rem;
  font-weight: 500;
}

.form_description_text {
  font-size: 1.4rem;
  font-weight: 500;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.smallFontMessage {
  font-size: 1.2rem;
  width: 15vw;
  text-align: center;
}
.textarea {
  border: none;
  outline: none;
  background: var(--transparent);
  width: 100%;
  overflow: auto;
  resize: none;
  font-size: 1.4rem;
  border-bottom: 2px solid var(--primary);
  padding-bottom: 0.5rem;
  font-weight: 500;
}
.textAreaColor {
  color: var(--white2);
}
.bx_algo_logo_image {
  height: 15rem;
  width: 15rem;
}

/************************************************ Error Message ***************************************/
.error_message {
  color: var(--red);
  font-weight: 500;
  font-size: 1.3rem;
  text-align: center;
}

.normal_message {
  color: var(--);
  font-weight: 500;
  font-size: 1.3rem;
}

/************************************************ Modal CSS *******************************************/

.modal_buttons {
  margin-top: 2rem;
  display: flex;
  width: 100%;
}

.modal_drag_and_drop_view {
  border-radius: 1.5rem;
  overflow: hidden;
  background-color: var(--gray);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: 15vw;
  width: 15vw;
  cursor: pointer;
}

.video_card_audio_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal_drag_and_drop_view_audio {
  border-radius: 1.5rem;
  overflow: hidden;
  background-color: var(--gray);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 18vw;
  width: 18vw;
  cursor: pointer;
}

.modal_actual_logo_image {
  height: 90%;
  width: 90%;
  border-radius: 1rem;
  background-color: var(--gray);
  object-fit: contain;
}

.modal_logo_image {
  height: 7rem;
  width: 7rem;
  background-color: var(--gray);
}

.modal_heading {
  color: var(--primary);
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: 600;
}

.bx_algo_edit_modal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.bx_algo_edit_modal_left {
  grid-column: 1/4;
}

.bx_algo_edit_modal_right {
  grid-column: 4/-1;
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media (max-width: 900px) {
  .main_container {
    padding: 2.5rem;
  }
}

@media (max-width: 769px) {
  .main_container {
    grid-column: 1/-1;
    left: 0;
    border-radius: 0;
    padding: 2rem;
  }

  .main_container_active {
    left: 0;
  }

  .bx_algo_edit_modal {
    grid-template-columns: 1fr;
  }

  .bx_algo_edit_modal_left {
    margin-top: 1rem;
  }

  .bx_algo_edit_modal_right {
    grid-row: 1/2;
    grid-column: 1/2;
    display: block;
  }

  .modal_drag_and_drop_view {
    margin: 1rem 0;
  }

  .form_description_text {
    font-size: 1.1rem;
  }

  .form_header_text {
    font-size: 1.3rem;
  }

  .bx_algo_logo_image {
    height: 9rem;
    width: 9rem;
  }
}

/* data not found */

.data_not_found_container {
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.data_not_found_container2 {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87rem;
}

.data_not_found_message {
  font-size: 2rem;
  color: var(--red);
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
