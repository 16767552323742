.container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  height: 80vh;
}
.leftContainer {
  width: 35%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  overflow: auto;
  align-items: center;
  box-shadow: 0px 4px 20px 5px rgba(30, 112, 152, 0.69);
  border-radius: 1rem;
  padding: 2rem;
  padding-bottom: 3rem;
  position: relative;
}
.middleContainer {
  width: 30%;
  height: 100%;
  /* flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center; */

  box-shadow: 0px 4px 20px 5px rgba(30, 112, 152, 0.69);
  border-radius: 1rem;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  overflow: auto;
}
.middleCard {
  border: 2px solid #bd796c;
  outline: none;
  appearance: none;
  width: 90%;
  height: 4rem !important;
  background-color: #ffccb6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  border-radius: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  color: #bd796c;
  overflow: auto;
  margin: 1rem auto;
}

.middleCard2 {
  position: relative;
  border: none;
  outline: none;
  appearance: none;
  width: 90%;
  height: 4rem !important;
  background-color: #bd796c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  border-radius: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--white2);
  overflow: auto;
  margin: 1rem auto;
}

.middleCard:focus,
.middleCard:checked {
  /* background-color: var(--secondary-light); */
  box-shadow: 0px 2px 20px 2px #ffccb6;
  font-weight: 500;
}

.rightContainer {
  width: 35%;
  height: 80vh;
  box-shadow: 0px 4px 20px 5px rgba(30, 112, 152, 0.69);
  border-radius: 1rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.5rem !important;
  column-gap: 3rem;
  padding: 2rem;
  overflow: auto;
  flex-grow: 1;
  align-content: flex-start;
}
.rightCard {
  height: 10rem;
  width: 10rem;
  font-size: 1.2rem;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  align-self: start;
}
.backgroundCard {
  height: 80%;
  width: 100%;
}
.background {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}
.backgroundImgContainer {
  width: 38rem;
  height: 24rem;
  position: absolute;
  top: 25%;
}

.avatarStyle {
  position: absolute;
  top: 36%;
}
.tagImgContainer {
  width: 7rem;
  height: 7rem;
  position: absolute;
  top: 50%;
  right: 15%;
}
.circle {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--yellow);
  font-size: 1rem;
  position: absolute;
  right: 5%;
  top: 40%;
}
.icon {
  width: 1.8rem;
  height: 1.8rem;
  background-color: transparent;
  position: absolute;
  right: 6%;
}
.coinBag {
  position: absolute;
  left: 6%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.coin {
  width: 2rem;
  height: 2rem;
}
