.clinic_main_content {
  margin: 5rem 0 3rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 3rem;
  overflow-y: auto;
  padding: 0 3rem 2rem 2rem;
  max-height: 72vh;
}

.clinic_form_top_container {
  display: flex;
}
.clinic_form_top_container3 {
  display: flex;
  margin-top: 2rem;
}
.clinic_form_top_container2 {
  display: flex;
  gap: 3rem;
}

.clinic_form_top_left_container {
  flex: 1.3;
}
.clinic_form_top_equal_container {
  flex: 1.5;
}

.clinic_form_image_upload_container {
  flex: 1;
  padding: 0rem 5rem;
}
.clinic_form_image_upload_container_column {
  flex-direction: column;
}
.clinic_form_image_upload_container_warning {
  color: red;
  font-size: 1.2rem;
  margin-top: 1rem;
}
@media (max-width: 769px) {
  .clinic_main_content {
    margin: 2rem 0 3rem 0;
  }
}

@media (max-width: 1100px) {
  .clinic_main_content {
    grid-template-columns: 1fr;
  }
}
