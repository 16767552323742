.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 3rem;
  column-gap: 3rem;
  margin-bottom: 5rem;
}
.containerError {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message {
  font-size: 2.4rem;
}
.containerError {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message {
  font-size: 1.8rem;
}
.studentcard {
  position: relative;
  width: 21.7rem;
  background: #313e76;
  box-shadow: 0px 4px 20px 5px rgba(30, 112, 152, 0.69);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}
.selectBox {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 2.8rem;
  height: 2.8rem;
  background: transparent;
  box-shadow: 2px 2px 4px rgba(49, 62, 118, 0.51),
    0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid #ffffff;
}
.editBox {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.8rem;
  height: 2.8rem;
  background: transparent;
  box-shadow: 2px 2px 4px rgba(49, 62, 118, 0.51),
    0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.avatarBox {
  position: absolute;
  top: 55%;
  right: 30%;
  width: 2.4rem;
  height: 2.4rem;
  background: var(--white2);
  box-shadow: 2px 2px 4px rgba(49, 62, 118, 0.51),
    0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 900;
}
.icon {
  width: 1.2rem;
  height: 1.2rem;
}

.iconAvatar {
  width: 1.2rem;
  height: 1.2rem;
  fill: var(--primary);
}
.checkmark2 {
  width: 1.3rem;
  height: 0.9rem;
}
.bigheadsContainer {
  margin-top: 5rem;
  width: 10rem;
  height: 10rem;
  z-index: 10;
}
.studentName {
  margin-top: 1rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #ffffff;
  text-transform: capitalize;
}
.coinContaier {
  margin-top: 1rem;
  display: flex;
  gap: 0.8rem;
}
.coin {
  width: 3rem;
  height: 3rem;
}
.points {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #d58814;
}
.backgroungContainer {
  position: absolute;
  top: 2.5rem;
}
.background {
  margin-top: 2.5rem;
  width: 100%;
  height: 10rem;
  border-radius: 1rem;
}
.tagContainer {
  position: absolute;
  top: 40%;
  right: 15%;
}
.tag {
  width: 4rem;
  height: 4rem;
  z-index: 15;
}
.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalHeader {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2rem;
  color: #ffffff;
  text-transform: capitalize;
}
.inputContainer {
  display: flex;
  gap: 10rem;
  margin-top: 1rem;
}
.inputContainer1 {
  flex-direction: column;
  gap: 2rem;
  flex-flow: column;
}
.inputSubContainer {
  margin-top: 1rem;
}
.label {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #ffffff;
  margin-left: 0.4rem;
  text-transform: capitalize;
}
.input {
  margin-top: 0.5rem;
  display: flex;
  width: 26rem;
  height: 4.2rem;
  background: #b6d7ff;
  box-shadow: 0px 0px 5px 5px rgba(30, 112, 152, 0.46);

  border-radius: 1rem;
  padding: 0.5rem 1.5rem;
  align-items: center;
}

.inputText {
  width: 100%;
  appearance: none;
  border: none;
  resize: none;
  outline: none;
  margin-left: 1rem;
  background-color: transparent;
  color: black;
}

.inputText::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

.genderContainer {
  margin-top: 1.5rem;
}
.chooseContainer {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
}
.radioContainer {
  display: block;
  position: relative;
  padding-left: 3.5rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  font-size: 1.4rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
}

.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.2rem;
  width: 2.2rem;
  background-color: #b6d7ff;
  border-radius: 50%;
}

.radioContainer:hover input ~ .checkmark {
  background-color: #b6d7ff;
}

.radioContainer input:checked ~ .checkmark {
  background-color: #b6d7ff;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radioContainer input:checked ~ .checkmark:after {
  display: block;
}

.radioContainer .checkmark:after {
  top: 0.5rem;
  left: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: #313e76;
}
.error {
  font-size: 1.2rem;
  color: var(--red);
  /* text-align: center; */
}
.modalIcon {
  width: 1.5rem;
  height: 1.5rem;
}
.modalButtonContainer {
  margin-top: 1.8rem;
  width: 15rem;
}

.errorMessage {
  color: var(--red);
  font-size: 1.4rem;
}
.button2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.textButton {
  align-self: center;
  margin-top: 1rem;
  cursor: pointer;
}
.button {
  width: 100%;
  padding: 1.2rem 0 1.2rem 0;
  background: rgba(182, 215, 255, 0.35);
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.line {
  width: 3.4rem;
  height: 0.1rem;

  background: #ffffff;
}
.text {
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.1rem;
}

.sleletonCard {
  flex-shrink: 0;
}
.sleletonHeader {
  width: 100%;
  align-self: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sleletonBox {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 0.5rem;
  background: transparent;
  box-shadow: 2px 2px 4px rgba(49, 62, 118, 0.51),
    0px 4px 4px rgba(0, 0, 0, 0.25);
}
.skeletonImage {
  margin-top: 1rem;
  width: 100%;
  height: 7rem;
}
.skeletonName {
  margin-top: 1rem;
  width: 100%;
  height: 2rem;
}
.skeletonCoin {
  margin-top: 1rem;
  width: 100%;
  height: 2rem;
}
