.app_button_container{
    display: flex;
    align-items: center;
    width: min-content;
    padding: 0.9rem 2rem;
    margin-top: 2rem;
    cursor: pointer;
    transition: all .5s;
    outline: none;
    border: none;
    border-radius: .4rem;
    background-color: var(--primary);
}
.app_button_container:hover{
    background-color: var(--primary-light);
}

.app_button_title{
    color: var(--white);
    font-size: 1.6rem;
    text-transform: capitalize;
}

.app_button_image{
    font-size: 2.4rem;
    height: 2.3rem;
    width: 2.3rem;
    color: var(--white);
    margin-left: 1rem;
}

@media (max-width: 769px) {
    .app_button_container{
        padding: 0.7rem 1rem;
    }
    .app_button_title{
        font-size: 1.3rem;
    }
    .app_button_image{
        font-size: 1.5rem;
        height: 2rem;
        width: 2rem;
    }
}