.faq_card_container {
  background-color: var(--gray);
  padding: 2rem 4rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
  min-width: min-content;
}

.faq_card_title_view {
  display: flex;
  justify-content: space-between;
}

.faq_card_leftview {
  width: 100%;
}

.faq_card_rightview {
  margin-left: 1rem;
  justify-content: space-between;

  display: flex;
}

.faq_card_editlogo {
  height: 2.3rem;
  width: 2.3rem;
  cursor: pointer;
}

/******************************************** Toggle Switch  ********************************************/

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 2.2rem;
}
.toggle-switch input[type='checkbox'] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: var(--lightest-blue);
  border-radius: 2rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: '';
  left: 0.2rem;
  top: 0.2rem;
  width: 1.8rem;
  height: 1.8rem;
  background-color: var(--light-grey);
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type='checkbox']:checked + .switch::before {
  transform: translateX(1.8rem);
  background-color: var(--primary);
}
.toggle-switch input[type='checkbox']:checked + .switch {
  background-color: var(--switch-background);
}

@media (max-width: 900px) {
  .faq_card_container {
    padding: 2rem;
  }
}

@media (max-width: 500px) {
  .faq_card_editlogo {
    height: 2rem;
    width: 2rem;
  }

  .toggle-switch {
    width: 3.5rem;
    height: 2rem;
  }

  .toggle-switch .switch::before {
    left: 0.2rem;
    width: 1.6rem;
    height: 1.6rem;
  }
  .toggle-switch input[type='checkbox']:checked + .switch::before {
    transform: translateX(1.5rem);
  }
}
