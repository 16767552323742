.table_box {
  width: 100%;
  overflow: auto;
  height: 65vh;
  border-radius: 1rem;
  padding-right: 1rem;
}

.table_content {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.2rem;
  text-align: left;
}

.table_content thead {
  position: sticky;
  top: 0;
  background-color: var(--primary-light);
  z-index: 1;
}

.table_content thead tr {
  background-color: var(--background);
  color: #1b2735;
  font-size: 1.4rem;
  height: 3.2rem;
  font-weight: 600;
}

.table_content thead tr th:last-child {
  border-top-right-radius: 1rem;
}

.table_content thead tr th {
  color: #fff;
  text-align: center;
  width: 15rem;
}

.table_content tbody tr {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--dark-grey);
  border-bottom: 1px solid #ccc;
}
.table_content tbody tr:hover {
  background-color: var(--lightest-grey);
}

.table_content tbody tr td {
  text-align: center;
  width: 15rem;
  /* border-bottom: 2px solid #ccc; */
}
