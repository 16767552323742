.clinic_card_container {
  background-color: #f5f4f4;
  box-shadow: 0 0.3rem 1rem 0.3rem rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  padding: 3rem;
  transition: all 0.3s ease;
}

.clinic_card_container:hover {
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.1);
}

.clinic_card_top_container {
  display: flex;
}

.clinic_card_image_container {
  flex: 1;
}

.clinic_card_image {
  height: 5rem;
  border-radius: 1rem;
}

.clinic_card_actions_container {
  flex: 5;
  display: flex;
  justify-content: flex-end;
}

.clinic_username {
  font-size: 1.7rem;
  margin-top: 1rem;
  text-transform: capitalize;
  font-weight: 500;
}

.clinic_mobile_email_container {
  display: flex;
}

.clinic_mobile_email {
  font-size: 1.5rem;
  font-weight: 500;
}

.clinic_userAddress_container {
  display: flex;
  justify-content: space-between;
}

.clinic_userAddress {
  font-size: 1.3rem;
  text-transform: capitalize;
  font-weight: normal;
  font-style: italic;
}

.clinic_card_ID_container {
  background-color: var(--secondary);
  align-self: flex-start;
  margin: 0rem 1rem;
  padding: 0.4rem 2rem;
  border-radius: 10rem;
  cursor: pointer;
}
.clinic_card_bottom_container {
  display: flex;
}

.approve {
  background-color: var(--primary);
}
.approve:hover {
  cursor: pointer;
}

.clinic_card_ID {
  font-size: 1.4rem;
  color: var(--bg-color);
}
.settings_icon {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
  cursor: pointer;
}
.clinic_show_config_modal {
  height: 48rem;
  padding: 0;
  margin: 0;
  overflow: auto;
}
.clinic_show_config_modal_header {
  font-size: 2.5rem;
  color: var(--primary);
  font-weight: 500;
  margin-bottom: 1rem;
}

.clinic_show_modal_label {
  font-size: 1.8rem;
  color: var(--primary);

  font-weight: 500;
  margin-bottom: 1rem;
}
.clinic_show_config_modal_section {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 4rem;
  column-gap: 10rem;
  padding: 1rem;
  border-bottom: 1px solid var(--primary);
  padding-bottom: 3rem;
}

.clinic_show_config_modal_tileContainer {
  width: 25%;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.clinic_show_config_modal_side {
  display: flex;
  gap: 1rem;
}
.clinic_show_config_modal_title {
  font-size: 1.3em;
  color: var(--primary);
}
.clinic_show_config_modal_icon {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
/* .clinic_show_config_modal_iconContainer {
  font-size: 2rem;
} */
.clinic_show_config_buttonContainer {
  display: flex;
  gap: 2rem;
}
.tooltip {
  font-size: 1.2rem;
  font-family: Raleway;
}
@media (max-width: 1100px) {
}

@media (max-width: 769px) {
  .clinic_mobile_email_container {
    display: block;
  }
}
