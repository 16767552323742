.total_answered{
    font-size: 1.5rem;
    font-weight: 500;
}

.right_wrong_answered{
    font-size: 1.5rem;
    font-weight: 500;
}

.progressbar_outer{
    width: 100%;
    background-color: var(--lightest-grey);
    height: 1rem;
    margin-top: 1rem;
    border-radius: 20rem;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.progressbar_inner_right{
    background-color: #04DA00;
    height: 1rem;
    border-radius: 20rem;
}
.progressbar_inner_wrong{
    background-color: #FF0000;
    height: 1rem;
    border-radius: 20rem;
}

.kid_avatar_container{
    height: 8rem;
    width: 8rem;
    margin: 0 2rem 0 0;
    position: relative;
}

.kid_avatar_background_image{
    width: 9rem;
}

.kid_avatar_tag_image{
    width: 2rem;
    position: absolute;
    top: 40%;
    right: -1rem;
}