.app_loader {
  position: fixed;
  display: block;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.2);
}
.app_loader_subcontainer {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: block;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  border-radius: 2rem;
  padding: 4rem 5rem;
  background-color: rgba(0, 0, 0, 0.2);
  align-self: center;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  height: 10rem;
  width: 10rem;
}

@media (max-width: 769px) {
  .app_loader .loader {
    height: 5rem;
    width: 5rem;
  }
}
