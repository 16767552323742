.main_content {
	display: grid;
	grid-template-columns: repeat(2, minmax(min-content, 1fr));
	grid-gap: 2rem;
	margin-top: 5rem;
}

.mission_graph_container {
	grid-column: 1/2;
	background-color: var(--gray);
	height: 40vh;
	border-radius: 2rem;
	padding: 3rem;
}

.space_graph_container {
	grid-column: 2/3;
	background-color: var(--gray);
	height: 40vh;
	border-radius: 2rem;
	padding: 3rem;
}
.user_graph_container {
	grid-column: 1/3;
	background-color: var(--gray);
	height: 40vh;
	border-radius: 2rem;
	padding: 2rem;
}

.graph_title_text {
	font-size: 1.5rem;
	font-weight: 500;
}
