.selectContainer {
  font-size: 60%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--primary);
  padding-bottom: 0.5rem;
}
.imageContainer {
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 5rem;
  overflow: hidden;
}

.image {
  height: auto;
  width: 4rem;
  padding: 1rem;
  color: var(--white);
}
.select {
  outline: none;
  border: none;
  font-family: inherit;
  font-size: 1.6rem;
  width: 100%;
  color: var(--primary);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../../../Assets/Images/down-arrow.png');
  background-repeat: no-repeat;
  background-position: right 4px top 0px;
  background-size: 1em;
  outline: none;
  display: inline-block;
  margin-left: 10px;
}
