.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.container_left {
  width: 50%;
  padding: 15px;
}
.title {
  font-size: 2.1rem;
  color: var(--primary);
}
.buttonContainer {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
}
.container_right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_right_video_uploader {
  text-align: center;
}
.danger {
  color: var(--red);
  font-size: 12px;
}
