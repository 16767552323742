.client_card_skeleton {
  background-color: #f5f4f4;
  box-shadow: 0 0.3rem 1rem 0.3rem rgb(0 0 0 / 10%);
  border-radius: 1.5rem;
  padding: 3rem;
  transition: all 0.3s ease;
}

.client_card_image_skeleton {
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 50%;
  background-color: var(--skeleton);
}

.client_card_heading_skeleton {
  height: 2rem;
  width: 14rem;
  background-color: var(--skeleton);
  margin: 0.5rem 0;
}

.client_card_spaces_skeleton {
  height: 1rem;
  width: 12rem;
  background-color: var(--skeleton);
  margin: 0.5rem 0;
}

.client_card_view_btn_skeleton {
  height: 1rem;
  width: 8rem;
  background-color: var(--yellow-skeleton);
  margin: 0.5rem 0;
}

.client_card_actions {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--light-grey);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clinet_card_videos {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--light-grey);

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.client_card_toggle_skeleton {
  height: 3rem;
  width: 5rem;
  border-radius: 10rem;
  background-color: var(--skeleton);
}

.client_card_edit_skeleton {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: var(--yellow-skeleton);
}

.clinet_card_delete_skeleton {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: var(--red-skeleton);
}

.clinet_card_video_title {
  height: 2rem;
  width: 12rem;
  background-color: var(--skeleton);
}

.clinet_card_video_skeleton {
  height: 10rem;
  width: 18.9rem;
  background-color: var(--skeleton);
}

.clinet_card_video_skeleton2 {
  height: 15rem;
  width: 25rem;
  background-color: var(--skeleton);
}

.clinet_card_video_name_skeleton {
  margin-top: 1rem;
  height: 1.5rem;
  width: 10rem;
  background-color: var(--skeleton);
}

.clinet_card_video_questions_sleleton {
  margin-top: 0.5rem;
  height: 1rem;
  width: 5rem;
  background-color: var(--yellow-skeleton);
}

.indicator__android_label_sleleton {
  height: 2rem;
  width: 10rem;
  background-color: var(--skeleton);
}

.indicator__android_sleleton,
.indicator__ios_sleleton {
  height: 2rem;
  width: 2rem;
  border-radius: 0.5rem;
  background-color: var(--yellow-skeleton);
}

.incomplete_stack_sleleton,
.complete_stack_sleleton {
  width: 8rem;
  display: flex;
  justify-content: center;
  transition: all 0.4s;
}

.incomplete_stack_sleleton {
  background-color: var(--skeleton);
}

.complete_stack_sleleton {
  position: absolute;
  border-radius: 1rem;
  bottom: 0;
  min-height: 0.3rem;
  background-color: var(--yellow-skeleton);
}

.incomplete_stack_sleleton2,
.complete_stack_sleleton2 {
  width: 4rem;
  display: flex;
  justify-content: center;
  transition: all 0.4s;
}

.incomplete_stack_sleleton2 {
  background-color: var(--skeleton);
}

.complete_stack_sleleton2 {
  position: absolute;
  border-radius: 1rem;
  bottom: 0;
  min-height: 0.3rem;
  background-color: var(--yellow-skeleton);
}

.chart__bar_label_skeleton {
  height: 2.2rem;
  width: 7rem;
  border-radius: 1rem;
  background-color: var(--skeleton);
}

.chart__bar_label_skeleton2 {
  height: 2.2rem;
  width: 6rem;
  border-radius: 1rem;
  background-color: var(--skeleton);
}

.tick_skeleton {
  height: 2rem;
  width: 3rem;
  background-color: var(--red-skeleton);
  position: absolute;
  left: -4rem;
  top: 2.7rem;
}

.date__input_skeleton2 {
  height: 3rem;
  width: 15rem;
  background-color: var(--yellow-skeleton);
  border-radius: 1rem;
}

.date__input_skeleton {
  height: 3rem;
  width: 8rem;
  background-color: var(--yellow-skeleton);
  border-radius: 1rem;
}

.date_input_filter_container_btn_skeleton {
  height: 3rem;
  width: 4rem;
  background-color: var(--skeleton);
  border-radius: 1rem;
}

.client_card_email_phone_skeleton {
  height: 2rem;
  width: 2rem;
  background-color: var(--skeleton);
}

.client_card_email_phone_text_skeleton {
  height: 1.5rem;
  width: 11rem;
  background-color: var(--yellow-skeleton);
}

.question_answer_skeleton {
  height: 1rem;
  background-color: var(--skeleton);
}

.skeleton {
  position: relative;
  overflow: hidden;
}

.skeleton::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(240, 240, 241, 0.1),
    rgba(231, 231, 234, 0.7),
    rgba(231, 231, 234, 0.1)
  );
  animation: progress 1s ease-in-out infinite;
}

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

/* ===============================================================================
============================= Top Content Skeleton ===============================
=============================================================================== */

.topDataSkeleton {
  display: flex;
  gap: 1rem;
  align-items: center;
  background: #2196f34d;
  border-radius: 1rem;
  margin: 0.5rem;
  width: 12rem;
}

.topDataSkeleton__name {
  width: 8rem;
  height: 1.9rem;
  background-color: rgb(11, 211, 24);
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
}

.topDataSkeleton__bar {
  height: 2rem;
  max-width: 80%;
  min-width: 5%;
  padding-left: 1rem;
  position: relative;
  overflow: hidden;
}

.folder--skeleton::after,
.header__heading--skeleton::after,
.folder__button--skeleton::after,
.formContainer__label--skeleton::after,
.inputBox--skeleton::after,
.formContainer__button--skeleton::after,
.topDataSkeleton__name::after,
.topDataSkeleton__view::after,
.topDataSkeleton__bar::after,
.chartSkeleton__stack p::after,
.skeletonDate__input::after,
.skeletonDate__input--label::after,
.dataSkeleton__ios::after,
.dataSkeleton__android::after,
.dataSkeleton__ios span::after,
.dataSkeleton__android span::after,
.indicatorSkeleton__android::after,
.indicatorSkeleton__ios::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(240, 240, 241, 0.1),
    rgba(231, 231, 234, 0.7),
    rgba(231, 231, 234, 0.1)
  );
  animation: progress 1s ease-in-out infinite;
}

.topDataSkeleton__view {
  margin-left: -2rem;
  /* background-color: #e1a82d88; */
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  /* border: 3px solid #fff; */
  padding: 0.5rem;
  position: relative;
  overflow: hidden;
}

.topDataSkeleton__col {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  /* row-gap: 1rem;  */
}

.table_skeleton_container {
  margin-top: 1rem;
  width: 100%;
  overflow: hidden;
  height: 65vh;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding-right: 1rem;
}
.table_skeleton_header {
  width: 100%;
}
.table_top_border {
  border-top-right-radius: 1rem;
}
.table_skeleton_header_item {
  width: 20rem;
  height: 3rem;
  background-color: var(--skeleton);
}
.table_skeleton_content {
  margin-top: 0.5rem;
  width: 100%;
  height: 2.6rem;
  background-color: var(--skeleton);
}
