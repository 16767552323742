.mission_dropdown {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-bottom: 1px solid var(--primary);
  padding-bottom: 0.5rem;
}
.mission_dropdown_select {
  outline: none;
  border: none;
  font-family: inherit;
  font-size: 1.7rem;
  width: 85%;
  color: var(--primary);
}
.dropdown_selectedColor {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
}
.mission_upload {
  margin-top: 2rem;
  display: flex;
}
.mission_upload2 {
  margin-top: 2rem;
}
.dropdown_smallfont {
  font-size: 1.3rem;
}
