.howto_container {
  height: 10rem;

  /* position: relative; */
}
.howto_section,
.howto_section_center {
  background-color: var(--primary-color2);
  display: flex;
  gap: 2rem;
  align-items: center;

  transition: all 1s;
}

.howto_section {
  position: absolute;
  top: 3rem;
  left: 3rem;
  /* transform: translate(-50%, -50%); */
  z-index: 1000;
}
.howto_section_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2);
}
.howto_section_blueCard,
.howto_section_orangeCard,
.howto_section_greenCard {
  display: flex;
  /* gap: 1rem; */
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  width: 25rem;
  overflow: hidden;

  font-family: inherit;
  border: none;
  cursor: pointer;

  position: relative;
}
.howto_section_orangeCard {
  background-color: var(--light-red2);
  box-shadow: 0px 2px 2rem 1px rgba(255, 77, 0, 0.47);
}
.howto_section_greenCard {
  background-color: var(--light-green2);
  box-shadow: 0px 2px 2rem 1px rgba(4, 218, 0, 0.49);
}
.howto_section_blueCard {
  background-color: var(--light-blue2);
  box-shadow: 0px 2px 2rem 1px rgba(30, 112, 152, 0.46);
}

.howto_section_card_tick {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--black2);
}
.howto_section_blueCard .howto_section_card_tick {
  fill: var(--blue2);
}
.howto_section_greenCard .howto_section_card_tick {
  fill: var(--green2);
}
.howto_section_orangeCard .howto_section_card_tick {
  fill: var(--orange2);
}
.card_tick_icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--green2);
}
.selectTypes_card_new_icon {
  height: 4rem;
  position: absolute;
  top: -1rem;
  right: 0;
}
.howto_section_blueCard_title,
.howto_section_orangeCard_title,
.howto_section_greenCard_title {
  color: var(--black2);
  font-size: 1.5rem;
  font-weight: 600;
  /* white-space: nowrap; */
  /* background-color: red; */
  width: 14rem;
}
.howto_section_blueCard_image,
.howto_section_orangeCard_image,
.howto_section_greenCard_image {
  position: relative;
  height: 7.5rem;
  min-width: 6rem;
  overflow: hidden;
}
.howto_section_blueCard_image img,
.howto_section_orangeCard_image img,
.howto_section_greenCard_image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 6rem;
  z-index: 100;
}
.howto_section_blueCard_image div,
.howto_section_orangeCard_image div,
.howto_section_greenCard_image div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 5rem;
  width: 5rem;
  z-index: 99;
  border-radius: 50%;
}
.howto_section_orangeCard_image div {
  background-color: var(--orange2);
}
.howto_section_greenCard_image div {
  background-color: var(--green2);
}
.howto_section_blueCard_image div {
  background-color: var(--blue2);
}

/* Animation */
.animated,
.animated3 {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.animated {
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}

.animated3 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* How to content */
.howto_content {
  border-top: 1px solid var(--light-blue2);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.howto_content video {
  /* height: calc(100vh - 40rem);
	width: 50vw; */
  height: 100%;
  width: 70%;
  border-radius: 2rem;
}
.howto_content_title {
  margin-top: 1rem;
  font-size: 2.5rem;
  font-weight: 200;
  text-transform: uppercase;
}
.selected_sections {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 2rem;
}
.selectTypes_card_arrow {
  height: 1rem;
}

/* Select types */
.selectTypes {
  border-top: 1px solid var(--light-blue2);
  height: calc(100vh - 30rem);

  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
.selectTypes2 {
  margin-top: 2rem;
  padding-top: 3rem;
  border-top: 1px solid var(--light-blue2);

  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  position: relative;
}
.selectTypes2 video {
  height: 100%;
  width: 70%;
  border-radius: 2rem;
  cursor: pointer;
}
.selectTypes_heading {
  font-size: 2.5rem;
  font-weight: 200;
  text-transform: uppercase;
}
.selectTypes_cards {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: center; */
}

/* for above mission,spaces,property card */

.selectTypes_cardTop {
  background-color: var(--light-blue2);
  border-radius: 1rem;
  padding: 1rem;
  overflow: hidden;
  box-shadow: 0px 2px 1rem 5px rgba(0, 0, 0, 0.2);
  width: 25rem;
  border: none;
  cursor: pointer;
  font-family: inherit;
  color: currentColor;
  text-align: left;
}

/* for below mission,spaces,property card */

.selectTypes_card_disabledDiv {
  /* background-color: rgba(0, 0, 0, 0.5); */
  backdrop-filter: blur(8px);
}

.blurEffect {
  filter: blur(2px);
  cursor: not-allowed !important;
}

.disabledMissionComingSoonText {
  position: absolute;
  top: 35%;
  left: 30%;
  z-index: 10;
}

.selectTypes_card2,
.selectTypes_card,
.selectTypes_card_disabled {
  background-color: var(--blue2);
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0px 2px 1rem 5px rgba(0, 0, 0, 0.2);
  /* height: 11rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border: none;
  cursor: pointer;
  font-family: inherit;
  color: currentColor;
  text-align: left;
  transition: all 0.4s;
}
.selectTypes_card_disabled {
  background-color: #3e3d3d;
  color: var(--light-grey2);
  width: 25rem;
}
.selectTypes_card_disabled .selectTypes_card_progress {
  background-color: #3e3d3d;
}
.selectTypes_card_disabled .selectTypes_card_image {
  filter: grayscale(80%) opacity(80%);
}

/* changes */
.selectTypes_card {
  width: 25rem;
  min-height: 9.3rem;
}
.selectTypes_card:hover,
.selectTypes_card2:hover {
  /* transform: translate(.5rem, .1rem); */
  box-shadow: 0px 2px 1rem 5px rgba(0, 0, 0, 0.4);
}

.selectTypes_card2 .selectTypes_card_content {
  margin-top: 0;
}
.selectTypes_card_content {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem 2rem;
  padding-bottom: 0.5rem;
  position: relative;
  height: 8rem;
}
.selectTypes_card_content_tick {
  position: absolute;
  top: 0rem;
  right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white2);
}
.selectTypes_card_image {
  height: 4rem;
  border-radius: 1rem;
}
.selectTypes_card_name {
  line-height: 1.4;
}
.selectTypes_card_name h4 {
  font-size: 1.5rem;
  font-weight: 500;
  /* background-color: red; */
  height: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selectTypes_card_name p {
  font-size: 1.2rem;
  font-weight: 400;
  font-style: italic;
}
.selectTypes_card_progress {
  background-color: var(--dark-blue2);
  box-shadow: 0px 2px 1rem 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0.5rem 1.5rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.selectTypes_card_progress_bar_container {
  position: relative;
  width: 90%;
  margin-top: -0.3rem;
}
.selectTypes_card_progress_bar {
  position: absolute;
  top: 0.5rem;
  left: 0rem;
  height: 1rem;
  width: 100%;
  background-color: var(--light-blue2);
  border-radius: 1rem;
}
.property_progress {
  /* margin-top: 1rem; */
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}
.property_progress_title {
  font-size: 1.3rem;
  font-weight: 400;
  width: 13rem;
}
.property_progress_bar {
  width: 60rem;
  padding: 0.2rem;
  background-color: var(--light-blue2);
  border-radius: 1rem;
}
.property_progress_bar div {
  height: 0.5rem;
  width: 80%;
  border-radius: 1rem;
  background-color: var(--green2);
}
.pdfContainer {
  min-width: 100rem;
  position: relative;
}
.pdfDocument {
  min-width: 100rem;
  height: 53rem;

  display: flex;
  justify-content: center;
  align-items: center;
}
/* .pdfPages {
  height: 100%;
} */
.pdfButtonContainer {
  align-self: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  width: 18rem;
  background-color: var(--primary-color2);
  border-radius: 1rem;
}
.button {
  cursor: pointer;
  background-color: var(--primary-color2);
  outline: none;
  appearance: none;
  border: none;
}
.prviousButton {
  width: 2rem;
  height: 2rem;
  fill: var(--white2);
}
.nextButton {
  width: 2rem;
  height: 2rem;
  transform: rotate(180deg);
  fill: var(--white2);
}
.pdfButtonLabel {
  font-size: 1.8rem;
}
.pdfbuttonSuperContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}
